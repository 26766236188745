input[type="checkbox"] {
  transform: scale(2);
  margin: 0em 1em 0em 1.5em;
}
.mb-7 {
  margin-bottom: 4em;
}

.config-container-filter {
  padding-bottom: 10em;
}