@import "utils/stylesheets/variables.scss";

.loading {
  height: 100%;
}

.administrator-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  background-color: $EXTRA_LIGHT_BLUE;
}

.administrator-seats {
  padding-right: 1em;

  .seats {
    font-size: 1.2em;
    text-align: right;
  }
}

.administrator-bottom-buttons {
  display: flex;
}

.company-headline {
  // Center text, but align left if there is more than one line
  // Different from `text-align: center;`
  display: flex;
  justify-content: center;

  // Spacing
  margin: 0 10%;
}

.company-headline h1 {
  margin: 0;
  font-size: 4em;
  font-variant: petite-caps;
}

.location-team-space {
  /* Removed overflow: hidden; to prevent individual scrolling */
  height: 100%;
  margin: 0 10%;
  /* Optionally, you can adjust padding or other properties here */
}

.administrator-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3%;
  flex-grow: 1;
}

.plannertech-bottom {
  font-size: 2em;
  font-variant: petite-caps;
  margin-left: 2em;
}

.administrator-buttons {
  font-size: 1.5rem;
  margin-right: 5%;
}

@media (max-width: 575.98px) {
  .administrator-container {
    width: 100%;
  }
}

/* Location and team styling */

.Location-team-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto; /* Enable vertical scrolling on the main container */
  box-sizing: border-box; /* Ensure padding and borders are included in width and height */
  padding: 20px; /* Optional: Add padding for better spacing */

  .Report-Popup {
    width: 40%;
  }
}

.Location-team-main-container h2 {
  font-size: 2em;
  font-variant: petite-caps;
}

.Location-team-boxes-scroll {
  display: flex;
  flex-wrap: wrap; /* Simplified from flex-flow: row wrap; */
  margin-top: 2%;
  margin-bottom: 2%;
  /* Removed overflow: auto; to prevent individual scrolling */
  /* Set height to auto to allow content to expand naturally */
  height: auto;
  padding: 10px; /* Optional: Add padding to prevent boxes from touching edges */
  box-sizing: border-box;

  /* Optional: You can still customize spacing between boxes */
}

.Location-team-box {
  /* Center text, but align left if there is more than one line
     Different from `text-align: center;` */
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  margin: 12px;
  height: 6.3em;
  width: 10.5em;
  border-radius: 10px;
  padding: 0 0.5em;
  box-sizing: border-box; /* Ensure padding and borders are included in width and height */

  @media (max-width: 575.98px) {
    width: 100%; /* Make boxes full width on small screens */
    height: auto; /* Allow height to adjust based on content */
  }
}

.Location-team-box-inner {
  font-size: 110%;
  color: black;

  p {
    text-align: center;
    margin: 0; /* Remove default margins */
    word-break: break-word; /* Ensure long words break to prevent overflow */
  }
}

.Location-repository-box-inner {
  font-size: 110%;
  color: white;

  p {
    text-align: center;
    margin: 0;
    word-break: break-word;
  }
}

.Location-team-fiSettings {
  position: absolute;
  right: 2%;
  bottom: 7%;
  width: 18%;
  height: 18%;
}

.Location-team-consume {
  position: absolute;
  right: 17%;
  bottom: 7%;
  width: 18%;
  height: 18%;
}

.Location-team-FiBarChart2 {
  position: absolute;
  left: 5%;
  bottom: 7%;
}

.Location-team-fiCopy {
  position: absolute;
  left: 20%;
  bottom: 7%;
}

.Location-team-addUser {
  position: absolute;
  left: 35%;
  bottom: 7%;
}

.forecast-team {
  font-size: 0.7em;
  position: absolute;
  top: 0;
  left: 5%;
  font-weight: 800;
  width: 100px;
  text-align: left;
}

.Location-team-box-color {
  background-color: $LIGHT_BLUE;
}

.Location-repository-box-color {
  background-color: $DARK_GREEN;
}

.Location-chatbot-box-color {
  background-color: $ORANGE;
}

.admin-icons-hovereffect {
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
}

.Location-team-box-hovereffect {
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 0px 5px black;
  }
}

.Location-team-fiPlus-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 100%;
  margin: auto;
  width: 100%;
  height: 40%;
  max-height: 90%;
}

.Location-team-fiPlus {
  width: 100%;
  height: 120%;
  cursor: pointer;
}

.back-button {
  width: 5em;
  font-size: 1.5em;
}

.logout-button {
  color: red;
  font-size: 1.3em !important;
}

