@import "utils/stylesheets/variables";

.SetupScreen {
  height: 100%;
  margin: 0 auto;
  //padding: 0 20%;
  overflow: scroll;
  overflow-x: hidden;
}

.SetupScreen-item-config {
  width: 100%;
  margin: 0 auto;
  background-color: $EXTRA_LIGHT_BLUE;
  padding-bottom: 10px;
}
