@import "utils/stylesheets/variables";

.MemberConfig-Day-container {
  background-color: $LIGHT_BLUE;
  display: inline-block;
  color: white;
  margin: 1%;
  padding: 1%;
  border-radius: 10px;
}

.Memberconfig-toggleButton.active {
  opacity: 1;
}

.Memberconfig-toggleButton {
  margin-left: 0 !important;
  opacity: 0.3;
}
