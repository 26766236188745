.container-compartment {
    border: 2px solid gray;
    border-radius: 10px;
    background-color: #f7f7f7;
    padding: 8px 8px 0px 8px;
    margin-bottom: 0.55rem;
    > p {
      text-align: center;
      font-size: 1.3rem;
    }
  }