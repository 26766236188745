@import "utils/stylesheets/variables";

.buttonDiv {
  text-align: center;
}

.addingDiv {
  margin-top: 5%;
}

.buttonPlus {
  background-color: rgb(175, 216, 243);
  border: none;
  padding: 4px 21px;
  text-align: center;
  text-decoration: none;
  color: $BLUE;
  display: inline-block;
  font-size: 60px;
  margin: 4px 2px;
  border-radius: 50%;
}

.EditSheetsTable {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  table-layout: fixed;
  margin: 0px 5% !important;
  width: 90% !important;

  th {
    text-overflow: ellipsis;
    overflow: hidden;

    &:nth-child(1) {
      width: 25%;
    }

    &:nth-child(2) {
      width: 25%;
    }

    &:nth-child(3) {
      width: 25%;
    }

    &:nth-child(3) {
      width: 25%;
    }
  }

  tbody tr {
    background-color: lightgreen !important;

    td {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  tfoot input {
    border: none;
    outline: none;
    width: 100%;
  }
}

.EditSheets-delete-button {
  margin-left: 5px;
}