@import "utils/stylesheets/variables";

.LoginScreen-main-container {
  background-color: $EXTRA_LIGHT_BLUE;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.LoginScreen-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: auto;
  min-height: 60%;
  position: relative;

  h1 {
    margin: 1.5rem auto;
    text-align: center;
    font-size: \min(5em, 13vw);
    font-weight: normal;
    font-variant: petite-caps;
  }

  .input-group {
    width: \min(30em, 70vw);
  }

  .input-group-text {
    width: 8.5em;
  }
}