@import "utils/stylesheets/variables";

.AssetViewScreen-hour {
  display:flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-image: linear-gradient($OCEAN_BLUE, #0097ce);
  }
}


.AssetViewScreen-asset-element-container {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.AssetViewScreen-asset-element-day {
  display:flex;
  flex-grow: 1;
}

.AssetViewScreen-asset-element-hour {
  display:flex;
  flex-grow: 1;
  &:hover{
    background-color: $EXTRA_LIGHT_BLUE;
  }
}

.AssetViewScreen-main-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  min-width: 900px;
  background-color: $EXTRA_LIGHT_BLUE;
}

.AssetViewScreen-main-container::-webkit-scrollbar {
  background: transparent;
  height: 20px;
}

.AssetViewScreen-main-container::-webkit-scrollbar-thumb {
  background-color: $BLUE;
}

.AssetViewScreen-asset-container {
  position: absolute;
  top: 150px;
  // transition: 1000ms height, 500ms width;
  display: flex;
  flex-direction: column;
  background-color: grey;
  border-radius: 10px;

  > div {
    width: 100%;
    background-color: $WHITISH;
    flex-grow: 1;
    border-radius: 10px;
    position: relative;
    & + div {
      margin-top: 0.25vw;
    }
  }
}

.AssetViewScreen-filter {
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 90px;
  left: 0%;
  border-radius: 10px;
  margin: 0;
  z-index: 5;
  > .AssetView-filter-input-field {
    // position: relative;
    width: 95%;
  }
}

.AssetViewScreen-top-left-container {
  position: fixed;
  top: 60px;
  left: 0%;
  z-index: 4;
  height: 90px;
  background: $EXTRA_LIGHT_BLUE;
  border-radius: 10px;
}

.AssetViewScreen-days-container {
  position: sticky;
  top: 58px;
  background-color: $OCEAN_BLUE;
  transition: width 0.5s, 500ms top, 1000ms height;
  display: flex;
  flex-direction: row;
  min-height: 90px;
  box-shadow: 0px 10px 20px -7px black;
  border-radius: 10px;
  overflow: hidden;
  z-index: 2;
}

.AssetView {
  position: sticky;
  top: 150px;
  left: 0%;
  // transition: 1000ms height, 500ms left;
  display: flex;
  flex-direction: column;
  background-color: grey;
  border-radius: 10px;
  z-index: 3;
  
  div {
    flex-grow: 1;
    position: relative;
    background-color: $OCEAN_BLUE;
    border-radius: 10px;
    box-shadow: 10px 0px 20px -7px black;

    & + div {
      margin-top: 0.25vw;
    }
  }

  p {
    font-size: 160%;
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: fit-content;
    margin-left: 0.5rem;
    
    // Overflowing text
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
