@import "utils/stylesheets/variables";

.ScheduleMemberHours {
  display: flex;
  flex-direction: column;

  span {
    // Styling
    background-color: $WHITISH;
    border-bottom: 1px solid lightgrey;
    font-size: 0.65em;
    padding: 6px 0.5em;

    // This element is automatically sized in height, which may look ugly when given too little space.
    // This is intentional!
    // For this reason, we can't include a padding in the vertical direction.

    // Fit correctly in container
    flex-basis: 100%;
    overflow: hidden;

    // Center text
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &:hover {
      background-color: $EXTRA_LIGHT_BLUE !important;
    }

    &.active {
      background-color: $BLUE;
      color: white;
    }
  }
}
