@import "utils/stylesheets/variables";

.TeamTab-main-container {
  background-color: $EXTRA_LIGHT_BLUE;
  min-height: 100%;
  width: 100%;
  padding: 4em 20%;
}

.CompetenceGroupTab-list {
  width: 40%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.CompetenceGroupTab-add-group {
  width: 7%;
  flex: 1;

  svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
