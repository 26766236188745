$size: 15em;

.Loading {
  // Center vertically and horizontally in whatever this is placed in
  position: relative;
  left: calc(50% - 7.5em - 20px); // 50% - $size / 2 - margin
  // This line is really smart - it activates, when the parent element has a specific height,
  // and otherwise it is just disabled.
  top: calc(50% - 7.5em - 20px);

  // Ensure the content doesn't "reach out" when spinning
  width: $size;
  height: $size;
  overflow: hidden;

  // Spacing to adjacent items
  margin: 20px;

  // Spinner
  div {
    // Sizing
    width: $size;
    height: $size;
  }

  p {
    // Center inside spinner
    // Uses a different method than the parent element, since it's width is variable
    position: absolute;
    left: 50%;
    top: 50%;
    // Reset the margin after left, and add 1em as the actual desired margin
    margin-right: calc(-50% + 1em);
    transform: translate(-50%, -50%);

    // Styling (slightly transparent-ish)
    text-align: center;
    color: #333;
    background-color: white;
    font-size: 1.5em;
    box-shadow: 0px 0px 10px 0px black;
    border-radius: 10px;
    padding: 0.5em;
  }
}
