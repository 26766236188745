.DataGridLayout-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  >h3 {
    margin: 0.5em;
  }

  .excelGrid-container {
    width: 100%;
    padding-left: 1em;

    .excelGrid {
      border-collapse: collapse;
      width: 100%;
      table-layout: auto; /* Ensure table layout is automatic */
    }

    .excelGrid th, .excelGrid td {
      border: 1px solid #ccc;
      width: 150px;
      /* Remove fixed height and use min-height instead */
      min-height: 30px;
      padding: 5px;
      vertical-align: top; /* Align content to the top */
      white-space: pre-wrap; /* Allow text to wrap and cells to expand */
      word-wrap: break-word; /* Break long words to prevent overflow */
    }

    .excelGrid th {
      background-color: #f5f5f5;
      text-align: left;
      /* Optional: Add font-weight if needed */
      /* font-weight: bold; */
      min-height: 50px; /* Ensure sufficient height for vertical centering */
    }

    .excelGrid td > div[contenteditable="true"] {
      width: 100%;
      /* Remove fixed height and use min-height instead */
      min-height: 1.5em;
      /* Allow the div to expand based on content */
      height: auto;
      display: block;
      padding: 4px;
      box-sizing: border-box; /* Include padding in width and height */
      overflow: hidden; /* Prevent scrollbars */
      white-space: pre-wrap; /* Preserve whitespace and allow wrapping */
      word-wrap: break-word; /* Break long words */
      /* Optional: Add smooth height transition */
      transition: height 0.2s ease;
    }

    /* Optional: Add styles for focused cells */
    .excelGrid td > div[contenteditable="true"]:focus {
      outline: none;
      border: 1px solid #007bff;
    }

    /* New Styles for Header Content */
    .excelGrid-header-content {
      display: flex;
      align-items: center;        /* Vertically centers the content */
      justify-content: space-between; /* Places header text on the left and icon on the right */
      width: 100%;                /* Ensures the flex container takes full width */
      height: 100%;               /* Ensures the container fills the table header's height */
      box-sizing: border-box;     /* Includes padding and borders in the element's total width and height */
    }

    .header-text {
      flex: 1;
      /* Optional: Add padding or margin if needed */
      /* margin-right: 0.5em; */
    }

    .sort-icon {
      display: flex;
      align-items: center; /* Vertically centers the icon within the span */
      justify-content: flex-end;
      /* Optional: Add padding or margin if needed */
      /* margin-left: auto; */
    }
  }
}

/* CSS to make the delete cell borders and background transparent */
.delete-cell {
  background-color: transparent !important;
  /* Make the background transparent */
  border: 0 !important;
  /* Remove the border */
  overflow: visible !important;
  /* Ensure the button is not clipped */
  width: 30px !important;
}

.delete-header {
  display: none;
}

.data-grid-config-buttons {
  width: 100%;
  display: flex;
  margin-bottom: 1em;
  justify-content: space-between;

  .data-grid-left-button {
    margin-left: 1em;
  }

  .data-grid-center-buttons {
    display: flex;
    gap: 3em;
  }

  .data-grid-right-button {
    padding-right: 5px;
  }
}

.add-row-btn-container {
  display: flex;
  align-self: flex-start;
  margin: 1em 1em;
}

