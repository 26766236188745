@import "utils/stylesheets/variables";

.AssetViewElement-task {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  overflow: hidden;
}

.AssetViewElement-task-external-team:hover {
  cursor: help !important;
  background-color: #222222;
}

.AssetViewElement-task:hover {
  background-color: $OCEAN_BLUE;
  filter: brightness(0.8);
  box-shadow: #2003fc 0px 0px 0px 4px;
  z-index: 2; 
}


.AssetViewElement-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%; 
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}