@import "utils/stylesheets/variables";

.ScheduleButtons {
  // Keep size in container
  flex-shrink: 0;

  display: flex;

  // Contain in a nice white box
  padding: 10px;
  background-color: white;
  box-shadow: 0px 0px 25px 1px black;
  // Ensure the box shadow overlaps further content
  position: relative;
  z-index: 100;

  white-space: nowrap;

  button {
    border-radius: 0.5em;
    svg {
      color: white;
    }
  }

  // Spacing between buttons
  $spacing: 10px;

  > div {
    display: flex;

    button {
      margin: 0 $spacing;
      &:first-child {
        margin: 0;
      }
    }
  }

  .leftmenu-button {
    flex-basis: 10%;
    justify-content: flex-start;
    margin-right: $spacing;
  }

  .date-buttons {
    flex-basis: 20%;
    flex-grow: 3;
    justify-content: flex-start;
  }

  .ScheduleButtons-filler {
    flex-basis: 20%;
    flex-grow: 3;
    justify-content: flex-start;
  }

  .center-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 10px;

    button {
      &:first-child {
        margin: 0 $spacing;
      }
    }

    h3 {
      margin: 0;
      font-size: 2em;
    }
  }

  .center-buttons-mobile {
    flex-basis: 20%;
    flex-grow: 6;
    justify-content: flex-start;
  }

  .member-planningmode-week-buttons {
    flex-basis: 20%;
    flex-grow: 3;
    justify-content: flex-end;

    svg {
      display: flex;
    }
  }

  .rightmenu-button {
    flex-basis: 10%;
    justify-content: flex-end;
    margin-left: $spacing;
  }

  // Move content left / right, based on open menus
  &.move-left {
    .leftmenu-button {
      flex-basis: 22rem;
    }
    .date-buttons {
      flex-basis: calc(20% - (20rem - 10%));
    }
  }

  &.move-right {
    .member-planningmode-week-buttons {
      flex-basis: calc(20% - (20rem - 10%));
    }
    .rightmenu-button {
      flex-basis: 20rem;
    }
  }

  > div {
    transition: 500ms flex-basis;
  }

  #dropdown-basic {
    margin: 0 10px
  }

  #config-member {
    margin: 0 10px
  }
}

.ScheduleButtons.admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.Schedule-help-dropdown {
  min-width: unset !important;
}

.ScheduleButtons-mobile {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 0px 25px 1px black;
  position: relative;
  z-index: 100;
  white-space: nowrap;
  height: 4rem;

  .date-buttons-mobile {
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    left: 2%;

    button:first-of-type {
      margin-right: 0.4rem;
    }

    svg {
      height: 1rem;
      width: 1rem;
    }
  }


  .right-side-components {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 2%;

    .member-planningmode-week-buttons-mobile {
      margin-left: 0.4rem;
    }
  }

  .center-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.token-button.btn.btn-success,
.token-button.btn.btn-danger {
  display: block;
  margin: 0 auto;
}
