@import "utils/stylesheets/variables";

.AdminHeader {
  height: 7em;
  background-color: $EXTRA_LIGHT_BLUE;
  border-bottom: 1px solid grey;

  display: flex;
  flex-direction: column;

  position: relative;
  justify-content: flex-end;

  padding: 0 20%;

  .TeamLocation {
    font-size: 1.25em;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.8em;
    padding-left: 2em;
  }
}

.AdminHeader-planningmode {
  position: absolute;
  right: 1em;
  top: 0.8em;

  button {
    font-size: 0.8em;
    border-radius: 8px;

    & + button {
      margin-left: 1em;
    }
  }
}

.DropDown {
  background-color: $EXTRA_LIGHT_BLUE;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -264%);
}

.AdminHeader-nav {
  display: flex;
  justify-content: space-evenly;

  button {
    border-radius: 10px 10px 0 0;
    font-size: 0.8em;
    white-space: nowrap;
  }
}
