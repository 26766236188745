// General colors used throughout the application
// TODO: Move instances of "lightgrey" and others into this file as well
// TODO: Don't call these by their color name, use names like `PRIMARY` instead

$EXTRA_LIGHT_BLUE: #dbebf8; // for backgrounds
$LIGHT_BLUE: #1d86d7; // for text
$SEMI_LIGHT_BLUE: #8cd6ff; // for highligting
$BLUE: #0078d6; // for highligting
$WHITISH: #fafaff; //slighty blue
$GREEN: #1dca85;
$DARK_GREEN: #00802a;
$GREEN_TASK: rgb(217, 255, 217);
$LIGHT_GREEN: #86ffb7;
$SEMI_LIGHT_GREEN: hsl(113, 74%, 82%);
$OCEAN_BLUE: rgb(90, 192, 229);
$LIGHT_GREY: #e9ecef;
$ORANGE: #e28743;
$BUTTON_BLUE: #0d6efd;
$BUTTON_BLUE_HOVER: #0b5ed7;


