.ReportConfig-container {
  display: flex;
  flex-direction: column;

    &.open {
      margin-bottom: 20rem;
    }

  h3 {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  .ReportConfig-buttons {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;

    button:first-child {
      margin-right: 20px;
    }
  }

  .ReportConfig-From {
    display: flex;
    margin-bottom: 20px;
  }

  .ReportConfig-To {
    display: flex;
    margin-bottom: 20px;

    .input-group-text {
      width: 63px;
    }
  }

  .ReportConfig-CheckBox {
    overflow: hidden;
    .input-group-text {
      margin-right: 0.9em;
    }
    input[type="checkbox"] {
      margin: 0;
      transform: scale(2);
    }

    &.open {
      margin-bottom: 12rem;
    }
  }

  .ReportConfig-DownloadBtns {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
