@import "utils/stylesheets/variables";

.ColorPicker {
  background-color: white;
  padding: 10px;
  border: 1px solid lightgrey;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  .circle-picker {
    width: 100% !important;
    justify-content: center;
  }
}

.TimezonePicker,
.TaskStatePicker {
  flex: 1 1 auto;
}

// Put on top of the `.btn-group > .btn.active` in MemberConfig
.TimezonePicker {
  z-index: 2;
}

.TaskStatePicker__single-value,
.TaskStatePicker__option {
  display: flex !important;
  align-items: center !important;

  svg {
    margin-left: 0.5em;
  }
}

// Used throughout the project, for elements that are the container for .HiddenInput
.HiddenInput-container {
  position: relative;
}

// Also customized in ConfigContainer
.HiddenInput {
  opacity: 0;
  left: 50%;
  bottom: 0;
  height: 0;
  width: 0;
  position: absolute;
}

.HiddenInput-flex-container {
  display: flex;
}

.Select {
  flex-grow: 1;
}

.DayPicker-Overlay {
  z-index: 3 !important;
}

.DayPicker-OverlayWrapper {
  padding: 0.25rem;
}

.ConfigContainer .DayPickerRadius {
  border-radius: 0 !important;
}
.EditorContainer .DayPickerRadius {
  border-radius: 0 !important;
}
.ConfigContainer .InvalidDayPickerRadius {
  border-radius: 0 !important;
  color: red;
}
.EditorContainer .InvalidDayPickerRadius {
  border-radius: 0 !important;
  color: red;
}

//border-bottom-right-radius: 0.25rem !important;
//border-top-right-radius: 0.25rem !important;

.Tabular {
  > tbody {
    tr:nth-child(odd) {
      td:nth-child(1) {
        background-color: #eceef1;
      }
    }
    tr:nth-child(even) {
      td:nth-child(1) {
        background-color: #e4e6e9;
      }
    }
    td {
      width: 50%;
      padding: 5px !important;
      vertical-align: middle;
    }
  }
}

.Disabled-Duration-Input {
  background-color: $LIGHT_GREY;
  opacity: 1;
}

// Class for elements in ReactSelect
.css-wsp0cs-MultiValueGeneric {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
