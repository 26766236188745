@import "utils/stylesheets/variables";

.ScheduleScreen {
  display: flex;
  flex-direction: column;

  // Make scrolling in inner content work properly
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

// Make centering work properly, while having the scroll bar in the right side
.ScheduleScreenContent {
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  // Styling
  transition: width 500ms, margin-left 500ms;

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $BLUE;
  }

  &.move-left {
    margin-left: 20rem;
    width: calc(100% - 20rem);
  }
  &.move-right {
    width: calc(100% - 20rem);
  }
  &.move-left.move-right {
    width: calc(100% - 40rem);
  }
}

.ScheduleScreenGrid {
  display: grid;
  // Center in container
  margin: 0 auto;
}

.ScheduleHeader {
  // Positioning
  position: sticky;
  top: 0;
  z-index: 11;
  // Center in container
  margin: 0 auto;
  // Keep size in container
  flex-shrink: 0;

  // Styling
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: $OCEAN_BLUE;
  box-shadow: 0px 0px 30px 5px gray;

  // Inner content
  display: flex;
}

.ScheduleCorner,
.ScheduleDay {
  // Styling
  text-align: center;
  padding: 0.938rem 0.313rem;
}

.ScheduleMemberAndHours {
  // Positioning
  position: sticky;
  z-index: 10;
  left: 0;

  // Styling
  background-color: $OCEAN_BLUE;
  box-shadow: 0px 0px 30px 5px gray;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;

  // Control inner content
  display: flex;
  // Styling
  margin: 0.156rem 0;
  .ScheduleMemberHours {
    flex-basis: 100%;
  }
  .ScheduleMemberElement {
    flex-basis: 80%;
    + .ScheduleMemberHours {
      flex-basis: 20%;
    }
  }

}

.ScheduleMemberElement {
  position: relative;
  height: inherit;

  // Styling
  background-color: $OCEAN_BLUE;
  padding: 0.2em;

  // Center content
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.ScheduleMemberElement-editable {
    cursor: pointer;
  }

  svg {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 1.5rem;
    cursor: copy;
    margin: 0.2em;
  }
}

.ScheduleElement {
  // Styling
  margin: 2.5px;
  box-shadow: 0px 0px 30px 5px gray;
}
