@import "utils/stylesheets/variables";

.TeamTab-main-container {
  background-color: $EXTRA_LIGHT_BLUE;
  min-height: 100%;
  width: 100%;
  padding: 2em 20%;
}

.TeamTab-member-container {
  width: 40%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .TeamTab-member-outlook-icon {
    cursor: initial;
    display: flex;
    justify-content: flex-end;
  }
}

.list-item-dnd {
  align-items: center;
}

.TeamTab-add-member {
  width: 5%;
  flex: 1;

  svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.TeamName-container {
  width: 100%;
  min-height: 100%;
  background-color: $EXTRA_LIGHT_BLUE;
  margin: 0 auto;
  padding-bottom: 1em;

  > p {
    text-align: center;
    font-size: 2.5rem;
    //padding: 2.5vh 0;
  }
}


