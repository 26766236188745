@import "utils/stylesheets/variables";

.references-panel {
  position: fixed;
  right: 0;
  top: 0;
  width: 0;
  height: 100vh;
  z-index: 9999;
  max-width: 400px;
  background-color: #f9f9f9;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow: hidden;


  order: 2;

  &.open {
    width: 20rem;
  }

  .references-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $BLUE;
    color: white;
    border-radius: 0.25rem;
    margin: 0.6em 0 0 0;

    h2 {
      margin: 0;
      padding: 0 1.2em;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5;
    }

    .close-button {
      background: none;
      border: none;
      color: white;
      font-size: 1.2rem;
      cursor: pointer;
      transition: transform 0.2s;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5em;

      &:hover {
        transform: rotate(90deg);
      }
    }
  }


  .references-panel-content {
    padding: 1rem;
    overflow-y: auto;
    flex-grow: 1;

    a {
      display: flex;
      align-items: center;
      padding: 12px 8px;
      margin: 4px 0;
      text-decoration: none;
      color: $BLUE;
      font-size: 0.8rem;
      font-weight: 500;
      border-radius: 10px;
      transition: background-color 0.3s, color 0.3s;

      &:hover,
      &:focus {
        background-color: rgba($BLUE, 0.1);
        color: darken($BLUE, 10%);
        text-decoration: none;
      }

      &:focus {
        outline: 2px solid $BLUE;
        outline-offset: 2px;
      }

      .link-icon {
        margin-left: auto;
        color: inherit;
        transition: transform 0.3s;

        a:hover &,
        a:focus & {
          transform: translateX(4px);
        }
      }
    }

    .divider {
      border: none;
      border-top: 1px solid #ccc;
      margin: 8px 0;
    }
  }
}

