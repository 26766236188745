@import "utils/stylesheets/variables";

.TeamConfig-toggleButtonGroup {
  margin-left: 2% !important;
}

.btn-check:checked + .TeamConfig-radioButton {
  background-color: #696969 !important;
  color: $LIGHT_GREY !important;
  margin-left: 2%;
}

.btn-check:not(:checked) + .TeamConfig-radioButton {
    background-color: $LIGHT_GREY !important;
    margin-left: 2%;
}

.btn-deleteToken {
  background-color: #f25f5f !important;
  border-color: #f25f5f !important;
  &:hover {
    background-color: #c23535 !important;
    border-color: #c23535 !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(225 83 97 / 50%) !important;
  }
}

.TokenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.55rem;

  > button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.tokenPopupContainer {
  width: 100%;
  margin: 0 auto;
  padding: 2em 20%;

  top: 10%;
  width: \min(50em, 100%) !important;

  .close-popup-button {
    margin: 1em auto 0;
    display: block;
  }

  div {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    flex-direction: column;

    .input-group {
      flex-direction: row;
      justify-content: center;
    }
  }

  .teamconfig-copy-clipboard {
    cursor: pointer;
    margin-left: 2em;
  }
}
