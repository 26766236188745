@import "utils/stylesheets/variables";

.AnalysisTasksMenu-main-container {
  text-align: center;
  height: 100%;
  
  overflow-y: auto;
  width: 100%;
  border-radius: 10px;
  background-color: inherit;
  background-color: $OCEAN_BLUE;

  .Pheading {
    font-weight: bold;
    font-size: 1.6rem;
  }
}

.AnalysisTasksMenu-main-container::-webkit-scrollbar {
  background: transparent;
  width: 7px;
}

.AnalysisTasksMenu-main-container::-webkit-scrollbar-thumb {
  background-color: $BLUE;
}

.AnalysisTasksMenu-main-container:hover {
  overflow-y: auto;
}
.AnalysisTasksMenu-tasks-container {
  justify-content: center;
  width: 100%;
}

.Task-container {
  background-color: $EXTRA_LIGHT_BLUE;
  display: flex;
}

.AnalysisTasksMenu-analysis-container {
  background-color: white;
  margin: 5%;
  border-radius: 10px;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 90%;
  cursor: pointer;
  position: relative;

  .yellow-warning-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      color: black;
      fill: yellow;
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  .hoverable-container {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: -3%;
    right: -1%;
    background-color: transparent;
  }
}

.AnalysisTasksMenu-p-container {
  width: 100%;
  margin: 1% 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 0.8rem;

  span {
    max-width: 80%;
  }
}
