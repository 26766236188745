// General CSS element styles

// TODO: Remove bootstrap stylesheet and use a CSS-reset or similar, see
// http://meyerweb.com/eric/tools/css/reset/.
// The bootstrap styles often conflict with the SPA-thinking, e.g. p and h1
// elements are given unnescessary margins, that we often need to remove later on.
// Besides, we basically only use the buttons and input fields, and we could
// quite easily implement those ourselves!
@import "utils/stylesheets/bootstrap.css";
@import "utils/stylesheets/classdefinitions";

// Hackfix overflow scrolling on iOS
// This is done since we can't use overscroll-behavior!
html,
body {
  overflow: hidden;
  width: 100%;
}

// TODO: Set `background-color` on `body`, so when you overscroll, it still looks nice

// SPA behaviour
html,
body,
#root {
  height: 100%;
}

// TODO: Remove this
p {
  margin: 0;
}
