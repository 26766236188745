@import "utils/stylesheets/variables";

.setup-container {
  font-size: 0.8rem;
  margin-right: 1rem;

  input {
    font-size: 0.8rem !important;
  }

  .input-group-text {
    font-size: 0.8rem;
  }

  div.mb-3.input-group {
    margin-bottom: 0.5rem !important;
  }

  textarea.form-control {
    font-size: 0.8rem;
  }

  .actionconfig-container-compartment {
    border: 2px solid gray;
    border-radius: 10px;
    background-color: #f7f7f7;
    padding: 10px 10px 0px 10px;
    margin-bottom: 0.55rem;
    > p {
      text-align: center;
      font-size: 1.1rem;
      padding: 0px 0px 5px 0px;
    }
  }
}

.actionconfig-constraint-checkbox {
  display: flex;
  align-self: center;

  input[type="checkbox"] {
    transform: scale(1.5);
    margin: 0 0.4em 0 0.5em !important;
  }
}

.action-links {
  display: flex;
  justify-content: center;
  background-color: $LIGHT_GREY;
  margin-right: 1rem;
  margin-bottom: 1rem;

  .action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0.5rem 1rem 0.5rem 1rem;
  
    .btn.btn-link {
      box-shadow: none;
      color: black;
      flex-grow: 1;
      font-size: 1.5rem;
    }
    
    svg {
      font-size: 1.3rem;
    }
  }
}

.action-links:hover {
  background-color: #dee2e6;
  cursor: pointer;
}