@import "utils/stylesheets/variables";

.SampleViewScreen {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.SampleView-sample-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;

  .SampleView-ToggleButtonGroup-Radio {

    margin: 0.5em 0 0.5em 0;
    .btn-check:checked+.sampleview-prio-btn {
      color: #FFF !important;
    }

    .sampleview-prio-btn {
      background-color: white;
      color: #000;
    }
  }
}

.SampleView {
  overflow-y: auto;
  height: 100%;
  overflow-x: auto;

  .sampleView-overhead-container {
    display: flex;
    margin: 15px 10px;
    align-items: center;

    .sampleview-filter-box {
      padding: 0px 5px;
    }

    .mb-3.sampleview-checkbox {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0px !important;
      .form-check {
        label.form-check-label {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      input.form-check-input {
        transform: scale(1.5);
        margin: 4px;
      }
    }

    .alert-triangle {
      fill: red;
      font-size: 2rem;
      margin-left: 1rem;
    }
  }


  > h3 {
    margin: 15px;
  }

  .samplegroup-row {
    display: flex;
    flex-direction: row;
    margin: 15px 10px;

    h3 {
      min-width: 250px;
      padding: 10px;
      line-height: 1.5em;
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      margin: 0 15px 0 0;
      cursor: pointer;
      // Styling
      background-color: $OCEAN_BLUE;
      border-radius: 10px;
      box-shadow: 10px 0px 20px -7px black;
      align-items: center;

      .sampleDeadline {
        // Overflowing text
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        vertical-align: center;
        min-width: 85px;
      }

      .sampleName {
        // Vertical bar between items
        border-left: 1px solid black;
        padding-left: 8px;

        // Overflowing text
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
      }

      .sampleIcons {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }

      .alert-triangle-sample {
        fill: red;
      }
    }

    .samplerow-analyses-grouped {
      flex-grow: 1;
      display: flex;
      position: relative;

      .samplerow-analysis {
        // Sizing
        flex-shrink: 0;
        width: 10rem;
        overflow: hidden;
        cursor: pointer;

        // Styling
        padding: 5px;
        border: 1px solid grey;
        border-radius: 5px;

        // Align vertically center
        display: flex;
        align-items: center;

        // Spacing between items
        margin-left: 0.625rem;
        &:first-child {
          margin: 0;
        }

        svg {
          flex-shrink: 0;
          margin-right: 0.313rem;
        }

        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .alert-triangle-analysis {
          fill: red;
        }

        .flag-analysis {
          position: absolute;
          top: 0.313rem;
          margin-left: 8.313rem;
        }
      }
      .samplerow-analysis:hover {
        background-color: $OCEAN_BLUE;
        filter: brightness(0.8);
        box-shadow: #2003fc 0px 0px 0px 4px;
        z-index: 6;

      }
    }
  }

  // Scrollbar
  &::-webkit-scrollbar {
    background: transparent;
    height: 0.75em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $BLUE;
  }
}

.sampleview-dropdown {
  position: relative;
  display: inline-block;
  left: 10px;
}

.sampleview-dropdown-content {
  display: block;
  position: absolute;
  background-color: $SEMI_LIGHT_BLUE;
  border-radius: 10px;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.sampleview-dropdown-content .sampleview-dropdown-item {
  color: black;
  font-weight: normal;
  font-size:1.2rem;
  padding: 0 15px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items:center;
}

.sampleview-dropdown-content .sampleview-dropdown-item:hover {
  background-color: $OCEAN_BLUE;
}

.sampleview-dropdown-header {
  cursor: default;
  display: flex;
  justify-content: center;
}

.Popup-content.sampleview-task-table-popup {
  padding: 2em 1em 0.5em 1em;
  width: auto;
  max-width: 80%;

  .sampleview-task-table-popup-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    h4 {
      text-align: center;
      margin-bottom: 1em;
    }

    .sampleview-task-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .sampleview-task-main {
      overflow-y: auto;
    }

    .sampletable-buttons {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .btn.btn-danger {
        margin-right: 2rem;
      }

      .btn.btn-primary {
        margin-right: 2rem;
        background-color: #a020f0;
        border-color: #a020f0;
        &:hover {
          background-color: #8211c9;
          border-color: #8211c9;
        }
        &:active {
          background-color: #8211c9;
          border-color: #8211c9;
        }
        &:focus {
          background-color: #8211c9;
          border-color: #8211c9;
          box-shadow: 0 0 0 0.25rem rgba(169, 81, 241, 0.644);
        }
      }
    }
  }
}
