.country-dropdown {
  .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
  }
}

.location-config {
  min-height: 22rem;
}
