@import "utils/stylesheets/variables";

.PerformerConstraints-container {
  width: 50%;
  margin: 0 auto;
  padding-bottom: 2%;
}

.PerformerConstraints-selector {
  width: 100%;
  display: block;
  background-color: white;
  border-radius: 10px;
  padding: 2%;

  svg {
    font-size: 150%;
    display: inline;
    margin-left: 2.5%;
    cursor: pointer;
  }

  .add-performer-constraint-btn {
    margin-left: 10px;
  }
}
.remove-performer-constraint-btn {
  margin-left: 10px;
  transform: scale(0.8);
}

.PerformerContraints-item {
  display: inline;
  margin: 1%;
}

.PerformerContraints-item button {
  font-size: 80%;
}

.PerformerConstraints-holder {
  padding: 2%;
  width: 100%;

  div {
    background-color: white;
    text-align: center;
    border-radius: 10px;
    padding: 1%;
    margin-top: 2%;
    width: 100%;
    display: inline-block;
  }

  span {
    font-size: 80%;
  }

  svg {
    display: inline;
    margin-left: 2.5%;
    cursor: pointer;
    font-size: 130%;
    stroke-width: 2;
  }
}

//custom button style
$CustomButtonStyleColor: #346691;

.CustomButtonStyle {
  background-color: $SEMI_LIGHT_BLUE !important;
  border-color: $SEMI_LIGHT_BLUE !important;
  color: $CustomButtonStyleColor;
}

.CustomButtonStyle:hover {
  color: $CustomButtonStyleColor;
}

.CustomButtonStyle:not(:disabled):not(.disabled):active,
.CustomButtonStyle:not(:disabled):not(.disabled).active,
.CustomButtonStyle.dropdown-toggle {
  color: $CustomButtonStyleColor !important;
}
