@import "utils/stylesheets/variables";

.red-text {
  color: red;
  font-weight: 900;
}
.project-config-info {
  color: red;
  margin-left: -5px;
  margin-top: 5px;
}

.project-priority-info {
  color: red;
  padding-left: 5px;
  margin-top: 5px;
}

.ProjectConfigurationPage-template-selector {
  display: block;
  margin: 0 auto 1rem auto;
  width: 10.5rem;
  z-index: 1000;

  .dropdown-menu {
  transform: translate(0px, 40px) !important;
}
}

.ProjectConfigurationPage-recurrence-button {
  display: flex;
  justify-content: flex-end;
  .btn.btn-link.btn-lg {
    box-shadow: none;
  }
}

.dropdown-item.ProjectConfigurationPage-template-draft {
  color: #777;
  font-style: italic;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProjectConfigurationPage-drawing {
  height: 15vw;
  width: 75%;
  margin: 0 auto;
  position: relative;

  > input {
    height: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.ProjectConfigurationPage-metadata-container {
  width: 35%;
  margin: 0 auto;

  .input-group-prepend {
    width: 40%;

    .input-group-text {
      width: 130%;
      justify-content: center;
    }
  }

  .Project-startDate {
    margin-bottom: 1em;
    width: 150%;

    .project-startDateText {
      width: 18%;
    }
    .Custom-DayPicker {
      width: 18%;
    }
    .project-startTimeText {
      width: 19%;
    }
  }

  input#Project-startDate-checkbox {
    margin: 0 10px;
  }

  .Project-deadline {
    margin-bottom: 1em;
    width: 150%;

    .project-deadlineText {
      width: 18%;
    }
    .Custom-DayPicker {
      width: 18%;
    }
    .project-deadlineTimeText {
      width: 19%;
    }
  }

  .Project-color {
    margin-bottom: 1em;

    .ColorPicker {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
    }
  }
  
  .Custom-DayPicker {
    width: 60%;
  }

  input[type="checkbox"] {
    transform: scale(2);
    margin: 0.8em 1em 0em 1em;
  }
}
.ProjectConfiguration-designToolHeader {
  display: flex;
  justify-content: center;
  align-items: center;

  .ProjectConfiguration-designToolHeader-invisible {
    width: 33%;
  }

  .ProjectConfiguration-designToolHeader-Connections {
    width: 33%;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  input[type="checkbox"] {
    transform: scale(1.3);
    margin: 0.8em 0.5em 0em 1em;
  }
}

.Edit-Create-Project {
  .ProjectConfigurationPage-metadata-container {
    width: 100%;
  }

  .ProjectConfigurationPage-drawing {
    width: 100%;
  }

  .PerformerConstraints-container {
    width: 100%;
  }
}
.prio-group-config {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  column-gap: 1rem;

  .project-group-config {
    display: flex;
  }

  .Project-priority {
    margin-bottom: 1em;
  }

  .projectGroup-setting {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    border-radius: 7px;
    margin-top: 4px;
  }
}