@import "utils/stylesheets/variables";

.ScrumView-Screen {
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 0 7% 0 7%;
}
.ScrumView-filter {
  width: 40px;
  justify-content: flex-end;
}

.ScrumView-Header {
  z-index: 4;
  padding: 10px;
  text-align: left;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: $OCEAN_BLUE;
  box-shadow: 0px 0px 30px 5px gray;
  display: flex;
  justify-content: space-between;
}
.ScrumView-HeaderLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.ScrumView-HeaderMiddle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.ScrumView-HeaderRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.ScrumView-Columns-Container {
  position: relative;
  display: flex;
  border: 1px solid lightgray;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px lightgray;
  overflow-x: auto;
}

.ScrumView-Columns-Container.syncing {
  pointer-events: none;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(211, 211, 211, 0.5);
  z-index: 11;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
}

.ScrumView-Columns-Container.syncing > :not(.spinner) {
  filter: blur(8px);
}

.ScrumView-Column-Container {
  border: 1px solid lightgray;
  border-radius: 5px;
  flex-shrink: 0;
  margin: 8px;
  height: 100%;
  width: 20%;
  padding: 5px;
  background-color: rgb(245, 244, 244);
  box-shadow: 0px 0px 10px 0px lightgray;
  overflow: auto;
}
.ScrumView-Column-Title {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.ScrumView-Column-Title-Element {
  border-radius: 8.77px !important;
  margin-left: 5px;
}
.addProjectButton {
  margin-bottom: 4px;
}
.ScrumView-Column-TaskList {
  padding: 8px;
  transition: background-color 0.5s ease;
  height: 70vh;
  overflow: auto;
  overflow-y: auto;
  &.true {
    background-color: skyblue;
  }
}
.ScrumView-Task-Container {
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  user-select: none;
  border-radius: 7px;
  background-color: $WHITISH;
  border: 1px solid lightgray;
  padding: 5px;
  margin-bottom: 8px;

  &.true {
    background-color: lightgreen;
    border: 3px solid darkblue;
  }
  p {
    line-height: 1;
    font-size: 0.9rem;
  }

  &:hover {
    filter: brightness(0.8);
  }
}

