@import "utils/stylesheets/variables";

.Message {
  // Place center, as the topmost element
  z-index: 3000000;
  position: absolute;
  right: -1%;
  transform: translate(-50%, 0);
  top: 80%;

  // Styling
  padding: 1rem 2rem;
  margin: 0;
  box-shadow: 0px 0px 30px 5px black;
  border: none;
  border-radius: 0.25rem;
  font-size: 1.2em;
}

.Message-cancel {
  // Overlap the content a little bit
  position: absolute;
  top: 0.1em;
  right: 0.1em;
  height: 1.2em;
  width: 1.2em;
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (min-width: 2000px) and (min-height: 1000px){
  .Message {
    right: 6%;
  }
}

@media (max-width:1000px){
  .Message {
    position: fixed;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -90%);
  }
}
