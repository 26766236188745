@import "utils/stylesheets/variables";

.ManageScreen-main-container {
  background-color: $EXTRA_LIGHT_BLUE;
  height: 100%;
  width: 100%;
  display: flex;
  // The `safe` value makes it work even on very short screens
  // Only works on Firefox atm. though...
  justify-content: safe center;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;

  button {
    font-size: 1.5em;
  }
}

.ManageScreen-Logo p {
  font-size: \min(5em, 13vw);
  font-variant: petite-caps;
}

.ManageScreen-TeamPlanButton {
  border-top: 1px solid black;
  padding-top: 3em;
}

.ManageScreen-MemberPlanButton,
.ManageScreen-TeamPlanButton {
  width: 16em;
  button {
    width: 100%;
  }
}

.ManageScreen-item {
  margin: 0 auto 1.5em;
  text-align: center;
}

.Managescreen-Logout {
  position: relative;
  left: 25%;
  margin-top: 2em;
}

@media (max-width: 575.98px) {
  .Managescreen-Logout {
    // Reset other styles
    position: initial;
    margin-top: 0;

    // Same as on ManageScreen-TeamPlanButton
    width: \max(20%, 13em);
    button {
      width: 100%;
    }
  }
}
