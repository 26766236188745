.ErrorBoundary {
  font-size: 1.5rem;
  box-shadow: 0px 0px 10px 0px black;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem;
  background-color: white;

  p {
    text-align: center;
  }
}
