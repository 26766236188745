@import "utils/stylesheets/variables";

.Popup {
  // Overlay
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 2000000;
  background-color: rgba(0, 0, 0, 0.35);

  // Align content center and to the top
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  // Make sure it doesn't take up all the available space
  padding: 4em;

  // Reset text stuff
  font-size: 1rem;
  white-space: initial;
  text-align: left;
}

.Popup-content {
  position: relative;
  display: flex;
  flex-direction: column;
  
  // Sizing
  // Reasonable width is set
  width: \min(60em, 100%);
  max-height: 100%;

  // Styling
  border: 1px solid #bababa;
  border-radius: 10px;
  background-color: white;
  padding: 1em 1em;

  // aligning every popup confirmDialog in an already existing popup
  .ConfirmDialog {
    top: 20%;
    width: \min(20em, 100%);
    text-align: center;
  }
}

.taskAndSampleContainer > .Popup > .Popup-content.ConfirmDialog {
  width: auto;
  text-align: center;
  padding: 1em 2em;
}

.Popup-cancel {
  // Overlap the content a little bit
  position: absolute;
  top: 0.4em;
  right: 0.4em;
  height: 1.7em;
  width: 1.7em;
  cursor: pointer;
}

.Popup-scroll {
  width: 100%;
  flex-grow: 1;
  // Scrolling
  overflow: auto;
  // Make scroll not apply to parent elements
  // TODO: This doesn't work on iOS, but there's a fix for it in src/index.scss, which makes it work somewhat
  // In short, in the future we should only place `Popup` components inside elements that can't be scrolled.
  //overscroll-behavior: contain;
}

// TODO: Move this @media magic number into a global variable
@media (max-width: 575.98px) {
  // Reset the previous styles so the popup fills the whole screen
  .Popup {
    padding: 0;
  }
  .Popup-content {
    // Sizing
    width: 100%;
    height: 100%;
    // Styling
    border-radius: 0;
    padding: 2em 1em;
  }
}

.Popup-with-title {
  display: flex;
  flex-flow: column;
}

.popup-singlemessage {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  animation: slideInUp 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.popup-singlemessage-message {
  padding: 20px 10px 10px 10px;
  font-size: 18px;
  color: #333333;
  text-align: center;
}

.popup-singlemessage-close {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  outline: none;
}

@keyframes slideInUp {
  0% {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -30%);
    opacity: 1;
  }
}

.popup-autoWidth {
  width: auto;
  max-width: 80%;
}