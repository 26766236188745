@import "utils/stylesheets/variables";

.ProjectTemplateTab-main-container {
  display: flex;
  flex-direction: column;
  background-color: $EXTRA_LIGHT_BLUE;
  align-items: center; 

  .project-tab-filter {
    width: 100%;
    padding-top: 2em;
    margin-left: 2em;

    .input-group {
      width: 20em;
    }
  }

  .ProjectGroupName-container {
    margin-top: 40px;
    margin-left: 50px;
    width: 100%;
  }

  .ProjectTemplateTab-templates-container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-content: flex-start;
  }
}

.ProjectTemplateTab-project-container {
  margin: 1em;
  display: flex;
  justify-content: center;
  align-items: center;

  // Sizing
  height: 5em;
  width: 10em;

  // Styling
  border-radius: 10px;
  padding: 10px;

  p {
    font-size: 0.8em;
    color: inherit;
  }

  svg {
    width: 40%;
    height: 40%;
    cursor: pointer;
  }
}

.ProjectTemplateTab-hovereffect {
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 0px 5px black;
  }
}

.ProjectTemplateDraft-withBorder {
  border: 0.2em solid red;
}