@import "utils/stylesheets/variables";

.GraphDesignTool-main-container {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  position: absolute;

  width: 100%;
  height: 100%;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.GraphDesignTool {
  height: 100%;
  width: 100%;
  background-color: #f8f8f8;
  border: 1px solid black;
  position: relative;
  overflow: hidden;

  // When the node is active (being dragged, or having dragged an edge from it)
  div.SvgNode.SvgNode-isActive,
  div.SvgNode.SvgNode-isActive:hover {
    background-color: $WHITISH !important;
    color: black !important;
  }

  // When dragging an edge from one task to another
  // The above takes precedence, so this doesn't happen when hovering normally
  &.GraphDesignTool-dragging .SvgNode:hover {
    background-color: $SEMI_LIGHT_BLUE !important;
    color: black !important;
  }
}

.GraphDesignTool-buttons {
  position: absolute;
  display: flex;
  width: 100%;
  padding: 1em;
  pointer-events: none;

  .btn-light {
    pointer-events: auto;
    cursor: pointer;
    box-shadow: 0px 0px 1px 1px black;
    border-radius: 5px;

    & + .btn-light {
      margin-left: 10px;
    }

    &.active {
      background-color: $BLUE;
      
      svg {
        color: white;
      }
    }

    &:nth-child(3) {
      margin-left: auto;
    }

    svg {
      padding: 3px;
      width: 2em;
      height: 2em;
      display: block;
    }
  }
}

.SvgNode {
  position: absolute;
  cursor: move;
  border-radius: 0.5em;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 0px 0.5em 0px black;

  span {
    font-size: 0.7em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0.3em;
  }

  svg {
    cursor: pointer;
    position: absolute;
    line-width: 2;
    margin: 0.3em;
    font-size: 0.7em;
  }

  &.SvgNode-withBorder {
    border: 0.2em solid red;

    svg {
      margin: 0.4em;
    }
  }
}