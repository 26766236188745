@import "utils/stylesheets/variables";

.setup-container {
  font-size: 0.8rem;
  margin-right: 1rem;

  input {
    font-size: 0.8rem !important;
  }

  .input-group-text {
    font-size: 0.8rem;
  }

  div.mb-3.input-group {
    margin-bottom: 0.5rem !important;
  }

  textarea.form-control {
    font-size: 0.8rem;
  }

  .container-compartment {
    position: relative;
    border: 2px solid gray;
    border-radius: 10px;
    background-color: #f7f7f7;
    padding: 8px 8px 0px 8px;
    margin-bottom: 0.55rem;

    > p {
      text-align: center;
      font-size: 1.1rem;
    }

    .remove-asset-btn {
      position: absolute;
      bottom: 2rem;
      right: 0.5rem;
      cursor: pointer;
      border: none;
      background: none;
      font-size: 1.5rem;
      color: var(--bs-dark);

      &:hover {
        color: var(--bs-danger);
      }
    }
  }

  .action-links {
    margin-right: 0;
    margin-bottom: 2rem;

    .action-container {
      font-size: 1rem;
    }
  }

  .add-new-asset {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto 0.5rem auto;

    p {
      font-size: 1.1rem;
    }

    svg {
      width: 1em;
      height: 1em;
      stroke-width: 2;
    }
  }
}

