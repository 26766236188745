@import "utils/stylesheets/variables";

.list-item {
  width: 100%;
  border-bottom: 1px solid $LIGHT_BLUE;
  padding: 0;
  border-radius: 10px;
  margin-bottom: 2%;
  overflow: hidden;
  display: flex;
  flex: 1;
  align-items: center;
  height: fit-content;
  padding: 0.2vw;
  background-color: $SEMI_LIGHT_BLUE;

  p {
    flex: 1;
    padding: 0.1em;
    font-size: 1.3rem;
    margin: auto 0;
  }

  svg {
    height: 100%;
    margin: 0 0.1em;
    font-size: 1.5rem;
    cursor: pointer;
    color: black;
  }
}
