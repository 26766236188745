.Metrics-table {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.Metrics-table {
  th,
  td {
    border: none !important;
  }

  th {
    width: 50%;
  }
}
