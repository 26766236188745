@import "utils/stylesheets/variables";

.ScheduleElement-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: white;
}

.ScheduleItem {
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  user-select: none;
  border-radius: 7px;
  border: 1px solid grey;
  background-color: $WHITISH;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.313rem;



  &.shouldShowState-2,
  &.shouldShowState-4 {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &.shouldShowState-3,
  &.shouldShowState-4 {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  &.shouldShowState-2 > div > div {
    align-items: flex-start;
  }

  &.shouldShowState-3 > div > div {
    align-items: flex-end;
  }

  &:hover {
    filter: brightness(0.8);
    box-shadow: #2003fc 0px 0px 0px 4px;
    z-index: 6;
  }

  // Spacing between items
  span {
    margin-left: 5px;
    &:first-child {
      margin-left: initial;
    }
  }

  svg {
    flex-shrink: 0;
  }

  .checkBox:hover {
    color: grey;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  .lock-open {
    cursor: pointer;
    color: white;
  }

  .lock-open-black{
    cursor: pointer;
    color: black;
  }

  .lock-closed {
    cursor: pointer;
    color: black;
  }

  .lighter-color {
    color: rgb(196, 193, 193);
  }

  .PlannedProjectAction-Onsite {
    //position: absolute;
    //top: 20%;
    //transform: scale(0.7);
    margin-bottom: 0.2em;
  }
}

.ScheduleItems-names {
  display: flex;
  flex-direction: column;
}

.PlanEditActionBtn.btn-primary {
  border-color: grey !important;
  border-width: medium !important;
}

.PlanEditTaskBtn.btn-primary {
  border-color: grey !important;
  border-width: medium !important;
}

.ScheduleElement-planning-overlay {
  background-color: lightgrey;
  width: 100%;
  height: 100%;
  position: absolute;

  p {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: rgb(63, 63, 63);
    width: 80%;
    height: fit-content;
    font-size: 90%;
    font-weight: bolder;
    text-align: center;
  }
}

.ScheduleElement-div {
  height: 100%;

  // Styling
  box-shadow: 0px 0px 0px 1px lightgrey;
  //border-radius: 1px;
  //-webkit-box-shadow:0px 0px 0px 1px lightgrey;
  background-color: $WHITISH;

  -webkit-appearance: none;
  cursor: pointer;

  &:hover {
    background-color: $EXTRA_LIGHT_BLUE;
  }

  &.drag-over {
    background-color: $GREEN;
  }

  &.invalid-area {
    cursor: initial;
    box-shadow: none;
    background-color: lightgray;
  }

  &.green-task {
    background-color: $GREEN_TASK;
    &:hover {
      background-color: $EXTRA_LIGHT_BLUE;
    }

    &.drag-over {
      background-color: $GREEN;
    }

    &.invalid-area {
      cursor: initial;
      box-shadow: none;
      background-color: lightgray;
    }
  }
}

.Green-task-config-popup {
  width: 50%;
}
.Mobile-Green-task-config-popup {
  width: 100%;
}

.warning-container {
  float: right;
}

.taskTabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
}


.btn-check:checked + .task-tab-btn {
  border-color: grey !important;
  border-width: medium;
  background-color: #0a58ca !important;
  color: #fff !important;
  cursor: pointer;
  border-radius: 11px;
}

.task-tab-btn {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #ddd !important;
  border-width: medium;
  border-radius: 11px;
  cursor: pointer;
  &:hover {
    background-color: #ddd !important;
    border-color: #ccc !important;
  }
}

.taskAndSampleContainer {
  height: 92%;
  // overflow: hidden;

}
.ScheduleElementHour-mobileChoice {
  display: flex;
  flex-direction: column;
  text-align: center;
  .ScheduleElementHour-mobileChoice-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 1em;
  }
  .ScheduleElementHour-mobileChoice-cancel {
    margin-top: 1em;
    text-align: center;
  }
}
