@import "utils/stylesheets/variables";

.CustomerConfig-toggleButtonGroup {
  margin-left: 2% !important;
  // margin-bottom: 1.2em;
}

.btn-check:checked + .CustomerConfig-radioButton {
  background-color: $LIGHT_GREY !important;
  // margin-left: 0%;
}

.CustomerConfig-allowedDomains {
  overflow: auto;
  max-height: 350px;
}