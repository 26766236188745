@import "utils/stylesheets/variables";

.RightMenu {
  // Styling
  padding: 0.5rem;
  text-align: center;
  height: 100%;
  background-color: $OCEAN_BLUE;
  display: flex;
  flex-direction: column;

  // Positioning / transition
  position: absolute;
  width: 20rem;
  right: -20rem;
  box-shadow: none;
  transition: box-shadow 500ms, right 500ms;
  z-index: 999; // To display box shadow above other content

  &.open {
    right: 0;
    box-shadow: -10px 0px 50px 0px black;
    
    .Report-Popup {
      width: 40%;
    }
  }

  .CloseMenu {
    // Positioning
    position: absolute;
    left: 0.4rem;
    top: 1rem;

    // Styling
    font-size: 2em;
    cursor: pointer;
  }
}

.RightMenuHeader-container {
  margin: 0.3rem 0 1rem 2.5rem;
}

.RightMenuBody-container {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.RightMenuHeader-nav {
  height: 100%;
  display: flex;
  justify-content: center;

  span {
    font-size: 1.5rem;
    margin: 0 2.5rem;
  }

  .ButtonAnalysis.btn-primary,
  .ButtonProject.btn-primary {
    border-color: #222;
    border-width: medium;
  }

  .ButtonAnalysis {
    border-radius: 10px 0px 0px 10px !important;
  }

  .ButtonProject {
    border-radius: 0px 10px 10px 0px !important;
  }

  .ButtonRight {
    border-radius: 8.77px !important;
    margin-left: 5px;
  }
}

.Popup-content.diagnostics.Popup-with-title {
  .Pheading {
    font-size: 2em;
    padding-top: 0;
  }
}

.Diagnostics {
  h4 {
    font-weight: normal;
    font-size: 1.5em;
    margin-top: 0.5em;
  }
  
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: $OCEAN_BLUE;
    color: white;
    font-size: 1.5em;
  }

  .nav-tabs .nav-link, .nav-tabs .nav-item.show .nav-link {
    background-color: $EXTRA_LIGHT_BLUE;
    color: black;
    font-size: 1.5em;
  }
}
