.RecurrenceConfig-container {
  display: flex;
  flex-direction: column;

  h3 {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  .RecurrenceConfig-startDate {
    width: 38%;
  }

  .RecurrenceConfig-select {
    width: 30%;
  }

  .Recurrence-number {
    width: 20%;
  }

  .RecurrenceConfig-buttons {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;

    button:first-child {
      margin-right: 40px;
    }
  }

  .RecurrenceConfig-Popup {
    width: 35%;
  }
}
