@import "utils/stylesheets/variables";

.upload-box {
  position: relative;
  width: 40%;

  padding: 20px 10px;

  margin: auto;
  margin-top: 1%;
  height: 30vh;

  background-color: rgb(175, 216, 243);

  border-radius: 25px;
  outline: none;

  ul {
    padding-inline-start: 10px;
  }

  p {
    margin-bottom: 0.5em;
  }

  p:last-child {
    margin-top: 0;
    margin-bottom: 1.5em;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    text-align: center;
  }
}
.UploadDropzone-integration-files {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .UploadDropzone-remove-file {
    background-color: rgb(175, 216, 243);
    border: 0;
    font-size: x-large;
    padding: 0 0 0 10px;
  }
}
