@import "utils/stylesheets/variables";

.IapMenu {
  width: 100%;
  height: fit-content;

  h2 {
    font-size: rem;
    margin-top: 1rem;
    margin-bottom: 0;
    font-weight: normal;
  }

  h3 {
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: normal;
  }

  .btn-check:checked+.iap-mode-btn {
    color: #FFF !important;
  }

  .iap-mode-btn {
    background-color: white;
    color: #000;
  }

  // .iap-mode-btn {
  //   width: unset;
  //   font-size: 100%;
  //   border-radius: 10px;
  // }

  // .iap-mode-btn:not(.active) {
  //   color: #000 !important;
  //   background-color: #fff !important;
  //   border-color: #fff !important;
  // }

  // .iap-mode-btn.active {
  //   border-color: rgb(0, 60, 255) !important;
  //   border-width: medium;
  // }

  .iap-bottom-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .run-iap-btn {
      width: 50%;
      font-size: 100%;
      margin: 1em 0;
    }

    .Left-Menu-line {
      color: inherit;
      width: 100%;
      border: 0.5px;
    }

    .unplan-iap-btn {
      width: 50%;
      font-size: 100%;
      margin: 1em 0;
      height: 38px;
    }
  }

  .lockUnlock-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .lockUnlock-btn {
      width: 45%;
      font-size: 100%;
      margin-top: 2em;
    }
  }
}

.Selectable {
  background-color: $EXTRA_LIGHT_BLUE;
  border-radius: 10px;
  padding: 0.25rem;
}


.mondays {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.sundays {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.LeftMenu-daypickers {
  .LeftMenu-reset-date-btn {
    color: $EXTRA_LIGHT_BLUE;
    margin-bottom: 2%;
    span {
      color: blue;
      cursor: pointer;
    }
  }

  .Include-today {
    margin-top: 7px;
    margin-left: -3px;
  }

  input[type="checkbox"] {
    transform: scale(1.7);
    margin: 0.8em 1em 0em 1em;
  }
}
