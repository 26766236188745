@import "utils/stylesheets/variables";

.integration-tab {
  width: 100%;
  min-height: 100%;
  background-color: $EXTRA_LIGHT_BLUE;
  margin: 0 auto;
  padding: 2em 20%;

  > p {
    text-align: center;
    font-size: 1.5rem;
    padding: 2.5vh 0;
  }

  h4 {
    padding-top: 1rem;
    padding-bottom: 0.3rem;
    font-size: 120%;
  }

  .close-popup-button,
  .submit-button {
    margin: 1em auto 0;
    display: block;
  }

  .automatic-sheet-container {
    display: flex;
    justify-content: center;
    align-items: center;
    > * {
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  .headerContainer {
    position: relative;
    text-align: center;
    margin-bottom: 1em;
  }

  .headerContainer p {
    margin: 0;
    font-size: 1.5rem;
  }

  .headerContainer button {
    position: absolute;
    right: 3.3rem;
    top: 0;

  }

  .error-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  .error-table th,
  .error-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .error-table th {
    background-color: #f2f2f2;
    text-align: left;
  }

  .error-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .error-table tr:hover {
    background-color: #ddd;
  }

  .error-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: $BLUE;
    color: white;
  }

}

.IcalIntegration {
  button {
    margin: 0 auto;
    display: block;
  }

  .popup button {
    margin: 1em auto 0;
  }
}
