@import "utils/stylesheets/variables";

.AnalysisConfigurationPage-drawing {
  height: 15vw;
  width: 75%;
  margin: 0 auto;
  position: relative;

  > input {
    height: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.AnalysisConfigurationPage-metadata-container {
  width: 50%;
  margin: 0 auto;

  .input-group-prepend {
    width: 40%;

    .input-group-text {
      width: 100%;
      justify-content: center;
    }
  }

  .Analysis-color {
    margin-bottom: 1em;

    .ColorPicker {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
    }
  }

  :invalid {
    box-shadow: none;
  }
}

.AnalysisConfigurationPage-constraints {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 2%;
}

.AnalysisConfigurationPage-constraints-selector {
  width: 100%;
  display: block;
  background-color: white;
  border-radius: 10px;
  padding: 2%;

  svg {
    font-size: 150%;
    display: inline;
    margin-left: 2.5%;
    cursor: pointer;
  }
}

.AnalysisConfigurationPage-constraints-item {
  display: inline;
  margin: 1%;
}

.AnalysisConfigurationPage-constraints-item button {
  font-size: 80%;
}

.AnalysisConfigurationPage-constraints-holder {
  padding: 2%;
  width: 100%;

  div {
    background-color: white;
    text-align: center;
    border-radius: 10px;
    padding: 1%;
    margin-top: 2%;
    width: 100%;
    display: inline-block;
  }

  span {
    font-size: 80%;
  }

  svg {
    display: inline;
    margin-left: 2.5%;
    cursor: pointer;
    font-size: 130%;
    stroke-width: 2;
  }
}

//custom button style
$CustomButtonStyleColor: #346691;

.CustomButtonStyle {
  background-color: $SEMI_LIGHT_BLUE !important;
  border-color: $SEMI_LIGHT_BLUE !important;
  color: $CustomButtonStyleColor;
}

.CustomButtonStyle:hover {
  color: $CustomButtonStyleColor;
}

.CustomButtonStyle:not(:disabled):not(.disabled):active,
.CustomButtonStyle:not(:disabled):not(.disabled).active,
.CustomButtonStyle.dropdown-toggle {
  color: $CustomButtonStyleColor !important;
}

.AnalysisConfigurationpage-sample-count {
  input.form-control {
    text-align: center;
  }
}

.AnalysisGroup-setting{
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  border-radius: 7px;
  margin-top: 4px;
}

.AnalysisGroup-setting:hover{
  background-color: #adb5bd;
}

.ConfigurationPage-performerConstraints-button {
  display: flex;
  justify-content: center; 

  button.btn.btn-link.btn-lg {
    color: black;
  }

  .btn.btn-link.btn-lg {
    box-shadow: none;
    margin: 0.5rem;
  }

  .fi-plus-icon {
    font-size: 2rem;
  }

  .fa-arrowUp-icon {
    font-size: 1.5rem;
  }

}

.Pheading.ConfigurationPage {
  padding-bottom: 0;
}

.ConfigPage-group-sample-container {
  display: flex;
  flex-wrap: wrap;
  // gap: 2%;
  row-gap: 1rem;
  column-gap: 1rem;
}

.ConfigPage-analysis-group {
  display: flex;
  // padding-right: 2%;
  
}

.ConfigPage-sample-count {
  display: flex;
}

.configurationPage-popup-list-of-changes {
  text-align: left;
}