.AppLoading {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.IapLoading {
  position: fixed;
  top: 0;
  z-index: 1000000;
  width: 100%;
  height: 100%;

  // Overlay
  background-color: rgba(0, 0, 0, 0.2);
}

.Popup-content.plannertech-welcome-info-message {
  max-height: calc(100% - 40px);
  padding-top: 2rem;
  overflow-y: auto;
  width: auto;
  min-width: 40%;
  white-space: pre-wrap;

  .Popup-scroll {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 575.98px) {
  .Popup-content.plannertech-welcome-info-message {
    // Sizing
    width: 90%;
    height: auto;
    // Styling
    border-radius: 10px;
    padding: 2em 1em;
    // Positioning
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.rdp-root {
  --rdp-accent-color: #4A90E2;
  --rdp-accent-background-color: #F0F8FF;
  --rdp-range_middle-color: #4A90E2;
}

.selected-day {
  background-color: #4A90E2;
  color: #F0F8FF;
  font-weight: bold;
  border-radius: 50%;
}
.rdp-month_caption {
  margin-left: 0.75rem;

}
