@import "utils/stylesheets/variables";

.MemberConfig-Day-container {
  background-color: $LIGHT_BLUE;
  display: inline-block;
  color: white;
  margin: 1%;
  padding: 1%;
  border-radius: 10px;
}

.btn-check:checked + .Memberconfig-checkboxButton {
  opacity: 1;
}

.Memberconfig-checkboxButton {
  margin-left: 0 !important;
  opacity: 0.3;
  z-index: 1 !important;
}

.btn-check:checked + .Memberconfig-radioButton {
  background-color: $LIGHT_GREY !important;
  margin-left: 2%;
}

.MemberConfig-availability {
  text-align: center;
  background-color: $EXTRA_LIGHT_BLUE;
  border-radius: 10px;
  padding: 1%;
  height: auto;
  width: 370px;
  margin: 0 auto;

  p {
    font-size: 1.5rem;
    margin-bottom: 1%;
  }
}

.MemberConfig-work-week {
  margin: auto;
  width: fit-content;
}

.Memberconfig-toggleButtonGroup {
  margin-left: 2% !important;
}
