@import "utils/stylesheets/variables";
.simulation-tab {
  // Existing styles
  width: 100%;
  min-height: 100%;
  background-color: $EXTRA_LIGHT_BLUE;
  margin: 0 auto;
  padding: 2em 20%;

  h1 {
    text-align: center;
    padding: 2.5vh 0;
  }

  Button {
    display: flex;
    margin: 0 auto;
  }

  .Pheading {
    font-weight: bold;
    font-size: 1.6rem;
  }

  .config-container {
    margin: 0 auto;
    margin-left: 25%;
  }
}
