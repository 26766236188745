@import "utils/stylesheets/variables";

// .AdminsConfig-container {
//     padding-top: 20px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// }

.AdminsConfig-container {
    background-color: $EXTRA_LIGHT_BLUE;
    min-height: 100%;
    width: 40em;
    padding: 2em 20%;
    overflow: auto;

    .AdminName-container {
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: $EXTRA_LIGHT_BLUE;
        margin: 0 auto;
        padding-bottom: 1em;

        >p {
            text-align: center;
            font-size: 2.5rem;
            //padding: 2.5vh 0;
        }
    }
}

.AdminsConfig-add-admin {
    width: 10%;
    flex: 1;
    margin: auto;
    svg {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}

.AdminsConfig-admin-list {
    // width: 40%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}