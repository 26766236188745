@import "utils/stylesheets/variables";

.SampleTable {
  // Scrollbar
  &::-webkit-scrollbar {
    background: transparent;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $BLUE;
  }

  thead>tr>th {
    text-align: center;
    vertical-align: middle;
  }

  tbody>tr>td {
    text-align: center;
    vertical-align: middle;
  }

  .sampletable-time-column {
    max-width: 120px;
    min-width: 105px;
  }

  .sampletable-checkbox-column {
    min-width: 80px;
  }

  .sampletable-productName {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sample-checkBox {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  input[type="checkbox"] {
    margin: 0 auto !important;
    transform: scale(2);
  }
}

.sample-table-planned-main-container {
  overflow-y: auto;
  max-height: 92%;
}

.sample-table-planned-main-container::-webkit-scrollbar {
  background: transparent;
  width: 7px;
}

.sample-table-planned-main-container::-webkit-scrollbar-thumb {
  background-color: $BLUE;
}

.sampletable-buttons {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: sticky;
  margin-top: 20px;
  min-height: 2.5rem;
}

.radio-buttons-add-samples {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  .buttonAnalysis.btn-primary,
  .buttonProject.btn-primary {
    border-color: #222;
    border-width: medium;
    box-shadow: none;
  }

  .buttonAnalysis {
    border-radius: 10px 0px 0px 10px !important;
  }

  .buttonProject {
    border-radius: 0px 10px 10px 0px !important;
  }
}

.confirm-buttons {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  button {
    margin: 0 10px;
  }
}

.sampletable-cancel {
  display: flex;
  justify-content: center;
  width: 50%;
}

.FailSamples-info-text {
  color: red;
}

.sample-additional-information-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  .analysis-samples-lock {
    font-size: 1.5rem;
  }

  button {
    font-size: 1rem;
  }
}

.add-samples-asset-view {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .analysis-samples-lock {
    font-size: 1.5rem;
  }

  button {
    font-size: 1rem;
    margin-left: 14rem;
  }
}

.Popup-content.sampletable-addsamples-popup {
  overflow-y: auto;
  width: \min(55em, 100%);
  margin: 2.5em;
  padding: 2em 1em 1em 1em;
}

.Popup-content.sampletable-additional-info-popup {
  padding: 2em 1em 0em 1em;
}

.Popup-content.ConfirmDialog {
  height: fit-content;
}

.sampletable-fail-button.btn-primary {
  background-color: #A020F0;
  border-color: #A020F0;
  &:hover {
    background-color: #8211c9;
    border-color: #8211c9;
  }
  &:active {
    background-color: #8211c9;
    border-color: #8211c9;
  }
  &:focus {
    background-color: #8211c9;
    border-color: #8211c9;
    box-shadow: 0 0 0 0.25rem rgba(169, 81, 241, 0.644);
  }
}

.sampleTable-row-overDeadline {
  background-color: #f0b0b0;
}

.sampleTable-row-illegal {
  background-color: #ffee00;
}

.print-container {
  display: none;
}

.explanation-text {
  text-align: right;
}

@media print { html, body { height: initial !important; overflow: initial !important; }}

@media print {
  .print-container {
    display: block;
    overflow: visible !important;
    visibility: visible;
  }
}
