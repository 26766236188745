@import "utils/stylesheets/variables";

.Popup-content.log-popup {
  .Popup-scroll {
    &::-webkit-scrollbar {
      background: transparent;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $BLUE;
    }
  }
}

.log-table {
  border-collapse: separate;
  border-spacing: 0;

  thead > tr > th {
    text-align: center;
    vertical-align: middle;
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
  }

  tbody > tr > td {
    text-align: center;
    vertical-align: middle;
  }

  .log-table-time-column {
    max-width: 120px;
    min-width: 105px;
  }

  .log-table-name,
  .log-table-task-name {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
