@import "utils/stylesheets/variables";

.DeleteAllUnplannedSamples-container {
  display: flex;
  flex-direction: column;

  button {
    margin: 0 auto;
  }
}

.DeleteAllUnplannedSamples-info-text {
  color: red;
  margin-top: 12px;
  margin-right: 5px;
}