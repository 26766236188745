@import "utils/stylesheets/variables";

// Remove the emoji button and align components
.rcw-conversation-container > .rcw-sender {
    max-height: none;
    min-height: unset;
    height: 100%;

    > .rcw-picker-btn {
        display: none;
    }
    > .rcw-new-message {
        margin-left: 6%;
        max-width: 85%;
        min-width: 85%;
        cursor: text;

        .rcw-input {
            overflow-y: auto;
            line-height: 21px;
            padding-left: 2%;
        }
    }

    .rcw-send {
        margin-left: 1%;
        margin-bottom: 2%;
    }
}

.chat-widget-container {
    position: fixed;
    z-index: 9998;
}

.rcw-stt-button {
  position: fixed;
  bottom: 6.80rem;
  right: 22.6rem;
  z-index: 9999;
  border: none;
  background: #00000000;
  color: $BUTTON_BLUE;
}
.rcw-stt-button:hover {
  color: $BUTTON_BLUE_HOVER;
}


.chatbot-dropdown {
  position: fixed;
  bottom: 39.3rem; // Adjust based on robot icon's vertical position
  right: 21.5rem;  // Adjust based on robot icon's horizontal position
  z-index: 10000;

  // Style the Dropdown.Toggle to remove default button styles
  .rcw-chatbot-button-dropdown-toggle {
    padding: 0 !important;               // Remove padding
    display: flex;                       // Ensure proper alignment
    align-items: center;                 // Center the SVG vertically
    justify-content: center;             // Center the SVG horizontally
    cursor: pointer;                     // Change cursor to pointer
    color: $BUTTON_BLUE;                  // Set the color to the primary color
  }
  &:hover {
    .rcw-chatbot-button-dropdown-toggle {
      color: $BUTTON_BLUE_HOVER;          // Change color on hover
    }
  }

  // Style the Dropdown.Menu to align correctly
  .dropdown-menu {
    transform: translateY(-10px);       // Slightly adjust the dropdown's position upwards
    margin-bottom: 0;                    // Remove any default margin
    min-width: 200px;                    // Set a minimum width to prevent it from being too narrow
    max-width: 300px;                    // Set a maximum width to prevent it from being too wide
    max-height: 35rem;                   // Set a maximum height to prevent it from being too tall
    right: 0;                            // Align the dropdown menu to the right of the toggle button
    left: auto;                          // Override any left alignment
    top: auto;                           // Override top positioning if necessary
    bottom: 100%;                        // Position the dropdown above the toggle button
    margin-bottom: 0.5rem;               // Add some space between the button and the dropdown
    overflow-y: auto;
  }

}



.rcw-client {
    .rcw-message-text {
        max-width: 300px !important;
    }
}

.rcw-response {
    .rcw-message-text {
        max-width: 300px !important;
    }
}

.rcw-conversation-container .rcw-header, .rcw-launcher, .rcw-close-button {
    background-color: $OCEAN_BLUE !important;
}

.rcw-header {
    > .rcw-title,
    span {
        color: var(--bs-body-color);
    }
}

// Configure the company logo in the chatbot
.rcw-widget-container.rcw-close-widget-container > .rcw-launcher {
    .rcw-open-launcher {
        width: 45px;
        height: 45px;
    }
    img {
        position: absolute;
        top: 12%;
        left: 12%;
    }
}

@media screen and (max-width: 801px) {
    .rcw-conversation-container {
        top: 20%;
        max-width: 100%;
        height: 80%;
        // display: initial;
        // flex-direction: initial;
        // height: initial;
    }

    .rcw-stt-button {
        bottom: 1rem;
        right: 90%;
    }
}

@media screen and (min-width: 802px) {
    .rcw-conversation-container {
        width: 370px;
    }
}


.rcw-refresh-button {
    position: absolute;
    top: 8rem;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $BLUE;
    border: none;
    color: white;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    padding: 0px 10px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition-duration: 0.4s;
}

.rcw-refresh-button:hover {
    background-color: $OCEAN_BLUE;
}
