@import "utils/stylesheets/variables";

.header-container {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 2.5vh 0;
}

.repository-company-headline {
  grid-column: 1 / -1;
  text-align: center;
  font-size: 2em;
  margin: 0;
}

.show-errors-button {
  grid-column: 2;
  justify-self: end;
  margin-right: 1em;
}

.upload-container {
  width: 100%;
  height: 100vh;
  background-color: $EXTRA_LIGHT_BLUE;

  h4 {
    padding-top: 1rem;
    padding-bottom: 0.3rem;
    font-size: 120%;
  }

  .repository-upload-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1em auto 0;
  }
}


.repository-upload-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50vh;

  padding: 20px 10px;

  margin: auto;
  margin-top: 1%;
  height: 40vh;

  background-color: $SEMI_LIGHT_GREEN;

  border-radius: 25px;
  outline: none;
  overflow: auto;

  ul {
    padding-inline-start: 10px;
  }

  p:last-child {
    margin-top: 0;
    margin-bottom: 1.5em;
    text-align: center;
  }
}

.UploadDropzone-repository-files {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin: 0 20px;

  .UploadDropzone-remove-file {
    background-color: $SEMI_LIGHT_GREEN;
    border: 0;
    font-size: x-large;
    padding: 0 0 0 10px;
  }
}

.repository-upload-popup {
  min-width: 50vw;
  max-width: 60em;
  width: fit-content;
}

.repository-pHeading {
  font-size: 110%;
}

.repository-upload-rejected-files {
  width: fit-content;
  margin: auto;

  >h4 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  >h4>span {
    color: red;
  }
}

.repository-upload-rejected-files-listgroup {
  max-height: 25vh;
  overflow: auto;
}

.repository-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.repository-processing-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
  margin-right: 20px;

  .repository-check-icon {
    margin: auto 5px auto 0;
    font-size: 1.5em;
  }

  .repository-process-info-text {
    display: flex;
    align-items: center;
    margin: 5px;
  }

}

.help-repository {
  width: 100%;
  height: 100vh;
  background-color: $EXTRA_LIGHT_BLUE;


  .update-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1em auto 0;
  }
 }
