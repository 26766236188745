@import "utils/stylesheets/variables";

.AssetsTab-main-container {
  padding: 10% 0% 10% 0%;
  background-color: $EXTRA_LIGHT_BLUE;
  height: fit-content;
  min-height: 100%;
  width: 100%;
  padding: 4em 20%;
}

.AssetsTab-list {
  width: 40%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.AssetsTab-add-asset {
  width: 7%;
  flex: 1;

  svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
