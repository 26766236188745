@import "utils/stylesheets/variables";

.ProjectTasksMenu-main-container {
  text-align: center;
  height: 100%;

  overflow-y: auto;
  width: 100%;
  border-radius: 10px;
  background-color: inherit;
  background-color: $OCEAN_BLUE;
}

.ProjectTasksMenu-main-container:hover {
  overflow-y: auto;
}

.ProjectTasksMenu-main-container::-webkit-scrollbar {
  background: transparent;
  width: 7px;
}

.ProjectTasksMenu-main-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  background-color: $BLUE;
}

.ProjectTasksMenu-tasks-container {
  justify-content: center;
  width: 100%;
}

.Task-container {
  background-color: $EXTRA_LIGHT_BLUE;
  display: flex;
}

.ProjectTasksMenu-analysis-container {
  margin: 5%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  padding: 0.2em 0.5em;
  word-wrap: break-word;
  position: relative;

  .ProjectTasksMenu-text {
    flex-shrink: 1;
    width: 85%;
    font-size: 0.8rem;

    p {
      line-height: 1.3;
    }
  }

  .hoverable-container {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: -3%;
    right: -1%;
    background-color: transparent;

    .project-yellow-warning-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      
      > svg {
        color: black;
        fill: yellow;
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }
}

.ProjectTasksMenu-analysis-container-standard-action {
  margin: 5%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0.2em 0em;
  position: relative;

  .ProjectTasksMenu-text {
    width: 100%;
    font-size: 0.8rem;
    margin: 0.2rem 0;

      h5 {
        margin: 0;
      }
  }

  .hoverable-container {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: -3%;
    right: -1%;
    background-color: transparent;

    .project-yellow-warning-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      
      > svg {
        color: black;
        fill: yellow;
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }
}

.RightMenuEditActionBtn.btn-primary {
  border-color: grey !important;
  border-width: medium !important;
}

.ProjectFiltering-selectGroup {
  width: 75%;
}

.ProjectFiltering-onOff {
  margin-bottom: 1em;
}

.ActionList-Caption {
  float: left;
  font-weight: 500;
  margin-left: 1.9em;
  margin-top: -0.7em;
}
.Filter-button {
  border-radius: 8.77px !important;
  margin-left: 5px;
}
.list-unstyled {
  padding-top: 1em;
  li {
    text-align: left;
    text-align: center;
  }
}
.big-filter-config {
  margin-bottom: 10em;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.FilterButtons-Container {
  hr {
    margin-bottom: 0.6rem;
  }

  h5 {
    margin-left: 0;
    margin-bottom: 0.8rem;
    float: none;
    padding-bottom: 0.5rem;
    font-size: 1.6rem;
    font-weight: bold;
  }

  .todoList-Buttons {
    margin-bottom: 1rem;
  }

  .Todolist-Textfilter-Filterlist {
    width: 95%;
    margin: 0 auto;
    display: flex;
  }
}

.standard-action-container {
  display: flex;
  justify-content: space-between;

  Button {
    margin-right: 5%;
  }

  .standard-action-dropdown {
    background-color: $BLUE;
    border-radius: 10px;
    min-height: 2rem;
    display: flex; // Use Flexbox for layout
    align-items: center; // Align items vertically in the center
    justify-content: space-between; // Distribute extra space evenly
    margin: 0 5%;
  
    h5 {
      color: white;
      margin: 0;
      margin-left: 0.5rem;
      padding: 0.5rem;
      font-weight: 400;
      font-size: 1.1rem;
    }
  
    svg {
      color: white;
      font-size: 1.5rem;
      margin-right: 1rem;
    }
  
    &:hover {
      background-color: $LIGHT_BLUE;
      cursor: pointer;
    }
  }
}