@import "utils/stylesheets/variables";

.LeftMenu {
  // Styling
  padding: 0.5rem;
  text-align: center;
  height: 100%;
  background-color: $OCEAN_BLUE;
  display: flex;
  flex-direction: column;

  // Positioning / transition
  position: absolute;
  width: 22rem;
  left: -22rem;
  box-shadow: none;
  transition: box-shadow 500ms, left 500ms;
  z-index: 999; // To display box shadow above other content

  &.open {
    left: 0;
    box-shadow: 10px 0px 50px 0px black;
  }

  .CloseMenu {
    // Positioning
    position: absolute;
    right: 0.4rem;
    top: 1rem;

    // Styling
    font-size: 2em;
    cursor: pointer;
  }
}

.LeftMenuHeader-container {
  width: 60%;
  margin: 0.3rem auto 1rem;
}

.MetricsMenuHeadline {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  font-size: 130%;
  text-align: center;
}

.LeftMenuBody-container {
  height: 100%;

  // Scroll content
  overflow-y: auto;
  overflow-x: hidden;
}

.LeftMenuBody-container::-webkit-scrollbar {
  background: transparent;
  width: 7px;
}

.LeftMenuBody-container::-webkit-scrollbar-thumb {
  background-color: $BLUE;
}

.LeftMenuBody-container:hover {
  overflow-y: auto;
}
