@import "utils/stylesheets/variables";

.ScheduleScreenMobile {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ScheduleScreenMobileContent {
  width: 100%;
  height:100%;

  // Scroll this element, with snapping. *REMOVED* because of negative user experience
  overflow: auto;
  // scroll-snap-type: both mandatory;
}

.ScheduleScreenMobileMember {
  // One "page" per element. *REMOVED* because of negative user experience
  // scroll-snap-align: start;

  // Fix sizing of child elements
  display: flex;
  flex-direction: column;

  > h4 {
    position: sticky;
    top: 0;
    margin: 0;
    z-index: 2; // Above .ScheduleMemberHours

    // Styling
    background-color: $OCEAN_BLUE;
    padding: 0.2em;
    text-align: center;
  }
}

.ScheduleScreenMobileMemberContent {
  display: flex;
  height: 100%;

  .ScheduleMemberHours {
    flex-basis: initial;
    box-shadow: 0px 0px 10px 1px black;
    z-index: 1; // Above .ScheduleScreenMobileBody
  }

  .ScheduleElement-container {
    flex-basis: 100%;
  }
}
