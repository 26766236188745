@import "utils/stylesheets/variables";

.ProjectViewScreen {
  height: 100%;
  width: 100%;

  // Control inner content
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ProjectViewContent {
  display: flex;
  flex-direction: column;
  height: 100%;

  // Scrolling
  overflow: auto;

  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $BLUE;
  }
}

.ProjectViewHeader {
  display: flex;

  position: sticky;
  top: 0;
  z-index: 11;

  // Center
  margin: 0 auto;

  // Styling
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: $OCEAN_BLUE;
  // TODO: Remove this box shadow, so we don't have to make a bunch of wack
  // stuff to make it work!
  box-shadow: 0px 0px 30px 5px gray;
}

.ProjectViewCorner,
.ProjectViewDay {
  // Styling
  text-align: center;
  padding: 15px 5px;

  .ProjectViewFilterBar {
    margin-top: 0.5em;
  }
}

.ProjectViewGrid {
  display: grid;
  max-height: 100%;
  max-width: 100%;

  // Center
  margin: 0 auto;
}

.ProjectViewProjectName {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: sticky;
  left: 0;
  z-index: 10;

  // Styling
  cursor: pointer;
  padding: 1em 0.5em;
  margin: 2.5px 0;
  background-color: $OCEAN_BLUE;
  box-shadow: 0px 0px 30px 5px gray;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  h4 {
    margin: 0;
    //white-space: nowrap;
    //text-overflow: ellipsis;
    //overflow: hidden;
    overflow-wrap: break-word;
    //line-break: auto;
  }

  & + .ProjectViewItem {
    // Don't show border on first item
    border-left: none;
  }
}

.ProjectViewItem {
  display: flex;
  padding: 2.5px;
  border-left: 1px solid lightgrey;

  .ScheduleItem {
    flex-basis: 10em;

    & + .ScheduleItem {
      margin-left: 0.5em;
    }
  }
  .ScheduleItem:hover {
    filter: brightness(0.8)!important;
  }
}


