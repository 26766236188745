@import "utils/stylesheets/variables";

.NavigationBar {
  // Positioning
  position: fixed;
  z-index: 10000;
  left: 50%;
  transform: translate(-50%, 0);

  // Styling
  border-radius: 0px 0px 10px 10px;
  background-color: $BLUE;
  box-shadow: 0px 0px 5px 1px black;
  color: white;

  // Enlarge on hover
  transition: height 500ms;
  height: 0.5em;
  overflow: hidden;
  &:hover {
    height: 3em;
  }

  svg {
    // Sizing
    height: 3em;
    width: 3em;
    padding: 0.5em;

    // Styling
    cursor: pointer;
    background-color: inherit;
    &:hover {
      background-color: $LIGHT_BLUE;
    }
  }
}
