@import "utils/stylesheets/variables";

.AssetConfig-Day-container {
  background-color: $LIGHT_BLUE;
  display: inline-block;
  color: white;
  margin: 1%;
  padding: 1%;
  border-radius: 10px;
}
