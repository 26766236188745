@import "utils/stylesheets/variables.scss";

.customer-loading {
  height: 100%;
}

.repository-storageUsed-text {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 0.8rem;
}

.customer-administrator-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  background-color: $EXTRA_LIGHT_BLUE;
}

.customer-administrator-header-buttons {
  display: flex;
  gap: 0.5em;
}

.customer-seats {
  font-size: 0.7em;
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  text-align: center;
}

.customer-company-headline {
  // Spacing
  display: flex;
  justify-content: center;
  margin: 0 10%;
}

/* Customer styling */

@import "utils/stylesheets/variables";

.Customer-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

.Customer-main-container h2 {
  font-size: 2em;
  font-variant: petite-caps;
}

.Customer-boxes-scroll {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2%;
  margin-bottom: 2%;
  overflow: auto;
}

.Customer-box {
  // Center text, but align left if there is more than one line
  // Different from `text-align: center;`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  margin: 10px;
  height: 5em;
  width: 8em;
  border-radius: 10px;
  padding: 0 0.5em;
}


.Customer-repository-box-inner {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;           /* Ensure the parent container height is used */
  text-align: center;
  overflow: hidden;       /* Ensure content does not exceed box */
  font-size: 80%
}

.Customer-box-inner {
  display: block;           /* Ensure block-level behavior for paragraphs */
  white-space: normal;      /* Allows text to wrap */
  word-wrap: break-word;    /* Breaks long words for wrapping */
  overflow-wrap: anywhere;  /* Adds extra wrapping support */
  word-break: break-word;   /* Ensures compatibility across browsers */
  margin: 0;                /* Remove default margins */
  overflow-y: hidden;         /* Allow scrolling for overflowed content */
  text-align: center;       /* Keep text centered */
  font-size: 80%;
}

.Customer-fiSettings {
  position: absolute;
  right: 5%;
  bottom: 7%;
  &:hover {
    transform: scale(1.2);
  }
}

.Customer-box-color {
  background-color: $LIGHT_BLUE;
}

.Customer-chatbot-box-color {
  background-color: $ORANGE;
}

.Customer-box-hovereffect {
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 1px 5px black;
  }
}

.Customer-fiPlus-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 100%;
  margin: auto;
  width: 40%;
  height: 40%;
  max-height: 90%;
}

.Customer-fiPlus {
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:hover {
    transform: scale(1.5);
  }
}

.customer-logout-button {
  width: 13%;
}

.repository-processing-icon {
  position: absolute;
  left: 5%;
  bottom: 5%;
  transform: scale(1.2);

  .repository-spinner {
    transform: scale(0.8);
  }
}

.Popup-content.center.popup-autoWidth.global-message-edit {
  min-width: 40%;

  .input-field {
    resize: none;
    border-radius: 10px;
    padding: 0.5rem;
  }
}
