@import "utils/stylesheets/variables";

.Popup-content.ConfirmDialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  text-align: center;
  padding: 1em 2em;
}

@media only screen and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) and (hover: none),
       only screen and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) and (hover: none) {
  .Popup-content.ConfirmDialog {
    padding-bottom: 0px;
  }
  .Popup-content.ConfirmDialog .Popup-scroll {
    height: fit-content;
  }
}

.ConfirmDialog-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1em;

  button:first-child {
    margin-right: 10px;
  }
}
