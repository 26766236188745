@import "utils/stylesheets/variables";

.EditorContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Editor-main-container {
  width: 100%;
  position: relative;
  flex-direction: column;
  // If in popup, don't scroll the popup, scroll this element!
  // Requires `height: 100%` on .ConfigContainer
  overflow-y: auto;
  overflow-x: hidden;
  // Make scroll not apply to parent elements
  // TODO: This doesn't work on iOS, but there's a fix for it in src/index.scss, which makes it work somewhat
  // In short, in the future we should only place these components inside elements that can't be scrolled.
  // overscroll-behavior: contain;
  flex-grow: 1;
  flex-shrink: 1;

  // When on it's own inside the config
  > .HiddenInput {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.EditorContainer-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  svg {
    cursor: pointer;
    height: 2em;
    width: 2em;
  }
}

.Editor-main-container::-webkit-scrollbar {
  background: transparent;
  width: 7px;
}

.Editor-main-container::-webkit-scrollbar-thumb {
  background-color: $BLUE;
}

.EditorContainer-buttons {
  color: black;
}
