@import "utils/stylesheets/variables";

.WhiteTaskConfig-Reccurence {
  display: flex;
  justify-content: flex-end;
}

.WhiteTaskConfig-Reccurence {
  .btn.btn-link.btn-lg {
    box-shadow: none;
  }
}

.big-white-task-config {
  margin-bottom: 15em;
}

.greyTaskTimeContainer {
  display: flex;
  flex-direction: column;
}

.greyTaskTimeCheckbox {
  display: flex;
  align-self: flex-start;
  justify-content: center;
  margin: 0 0 1rem 1rem;
  div > span {
    margin-left: 1em !important;
  }

  input[type="checkbox"] {
    transform: scale(2);
    margin: 0;
  }
}

.mb-3.greytask-checkbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-check {
    label.form-check-label {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  input.form-check-input {
    transform: scale(1.5);
    margin: 4px;
  }
}
