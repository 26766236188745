// Contains helper classes used throughout the application

@import "variables";

// Override the bootstrap primary button style
.btn-primary {
  background-color: $BLUE;
}

// Used on `p`-elements that are headings
.Pheading {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 130%;
  text-align: center;
}

.setupHeading {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 180%;
  text-align: center;
}