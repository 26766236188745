@import "utils/stylesheets/variables";

.LibrarianScreen-main-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.Librarian-history {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 260px;
  background-color: $SEMI_LIGHT_BLUE;

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .Librarian-new-chat-button {
      height: 100%;
      display: flex;
      align-items: center;
      align-self: center;
      gap: 10px;
      width: 100%;
      color: white;
      background-color: $BLUE;
      border-radius: 5px;
      border: 0px;
    }

    .Librarian-new-chat-button:hover {
      background-color: #0b5ed7;
      border-color: #0a58ca;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
}

.Librarian-history-conversations {
  height: 90%;
  overflow-y: auto;
}

.Librarian-history-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2%;

  p {
    display: flex;
    justify-content: center;
    margin: auto;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.chat-window {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  justify-content: space-between;
  background-color: $EXTRA_LIGHT_BLUE;
}

.chat-messages {
  overflow-y: auto;
  flex-grow: 1;
  margin: 2% 20% 2% 20%;
  padding-right: 1rem;
  z-index: 3;

}

.chat-messages-mobile {
  overflow-y: auto;
  flex-grow: 1;
  margin: 2% 20%;
  padding-right: 1rem;
}

.chat-messages-empty-board {
  display: flex;
  height: 100%;
  width: 100%;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    text-align: center;
  }
}

.chat-messages-toolbar {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: white;
}

.chat-messages-toolbar-dropdown {
  margin-left: 0;
  margin-right: auto;
}

.Librarian-toolbar-buttons {
  display: flex;
  right: 10px;
  gap: 10px;
  transition: margin-right 0.3s ease-in-out;

  &.open {
    transition: margin-right 0.3s ease-in-out;
    margin-right: 20rem;
  }
}

.Librarian-selected-chatbot {
  margin-left: 0.6rem;
  font-weight: 600;
  font-size: 1.2rem;
  color: teal;
  z-index: 2;
}

.Librarian-selected-chatbot-mobile {
  position: absolute;
  align-self: center;
  top: 6.2em;
  z-index: 2;
  font-weight: 600;
  font-size: 16px;
  color: teal;
}

.Librarian-toolbar-buttons-mobile {
  display: flex;
  margin-left: 10px;
  transition: margin-right 0.3s ease-in-out;

  &.open {
    transition: margin-right 0.3s ease-in-out;
    margin-right: 20rem;
  }
}

.Librarian-toolbar-buttons-navigation {
  display: flex; /* Makes the navigation container a flex container */
  position: absolute;
  justify-content: center; /* Centers buttons horizontally */
  align-items: center; /* Centers buttons vertically */
  gap: 10px; /* Adds space between buttons */
}

.Librarian-toolbar-buttons-navigation-mobile {
  display: flex; /* Makes the navigation container a flex container */
  justify-content: center; /* Centers buttons horizontally */
  align-items: center; /* Centers buttons vertically */
  gap: 10px;
}


.chat-message-container {
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  max-width: 1200px;
  margin-left: auto; /* these two lines will center */
  margin-right: auto; /* your container on the page */
  box-sizing: border-box;
  border-radius: 10px;
}

.chat-message {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 0;  /* Prevent it from growing */
  flex-shrink: 0;  /* Prevent it from shrinking */
  flex-basis: 80%; /* Set base width */
  margin: auto; /* Centers the element within its container */
  overflow-x: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;

  .references-button {
    margin-top: 1em;
    margin-left: 0;
    width: 10rem;
  }
}

.chat-message-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid $WHITISH;
  background-color: $WHITISH;
  border-radius: 5px;
}

.librarian-logo {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.chat-config {
  display: flex;
  align-items: flex-end;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 2%;

  .chat-dropdown {
    margin-right: 0.5rem;

    .dropdown-menu.show {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}

.chat-input {
  display: flex;
  background-color: #fff;
  align-items: flex-end;
  border-radius: 10px;
  width: 100%;

  textarea {
    max-height: 15rem;
    flex-grow: 1;
    padding: 10px;
    border: none;
    border-radius: 20px 0 0 20px;
    resize: none;
    overflow: auto;
    outline: none;
    overflow-y: hidden;

    // Scrollbar styles for Firefox
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }

  .chat-button {
    background-color: #fff;
    border: none;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;

    svg {
      color: $BUTTON_BLUE;
      width: 18px;
      height: 18px;

      &:hover {
        color: $BUTTON_BLUE_HOVER;
      }
    }
  }

  .microphone-button {
    @extend .chat-button;

    background-color: #fff;

    svg {
    }
  }

  .send-button {
    @extend .chat-button;

    background-color: $BUTTON_BLUE;
    width: 2.2em;
    height: 2.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.2rem;
    margin-bottom: 0.25rem;

    svg {
      height: 1.2em;
      width: 1.2em;
      color: white;
      &:hover {
        color: white;
      }
    }
    &:hover {
      background-color: $BUTTON_BLUE_HOVER;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.bot-message {
  background-color: $WHITISH;
}

.burger-menu {
  position: absolute;
  left: 10px;

  &:hover {
    cursor: pointer;
  }
}

// .user-message {
// }

/* For Webkit browsers (Chrome, Safari) */
.chat-messages::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.chat-messages::-webkit-scrollbar-track {
  background: $WHITISH; /* color of the tracking area */
}

.chat-messages::-webkit-scrollbar-thumb {
  background: $LIGHT_BLUE; /* color of the scroll thumb */
  border-radius: 4px; /* roundness of the scroll thumb */
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background: $BLUE; /* color of the scroll thumb on hover */
}

.thinking-dots {

  font-weight: bold;

  &::after {
    display: inline-block;
    animation: dots 1s steps(1, end) infinite;
    content: '';
  }
}

@keyframes dots {
0%, 25% {
  content: '';
}
26%, 50% {
  content: '.';
}
51%, 75% {
  content: '. .';
}
76%, 100% {
  content: '. . .';
}
}

@media screen and (max-width: 750px) {
  .chat-messages {
    margin: 10% 0 5% 5%;
    padding: 0;

    .chat-message-container {
      margin-right: 5%;
    }
  }
  .chat-config {
    margin: 0 5% 2% 5%;
  }

  .chat-messages-mobile {
    margin: 20% 0 5% 5%;
    padding: 0;

    .chat-message-container {
      margin-right: 5%;
    }
  }


  .Librarian-history {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    border: none;
  }

  .Librarian-history.visible {
    transform: translateX(0);
  }

  .chat-messages-toolbar {
    min-height: 3.5rem;
  }

  .chat-messages-toolbar-dropdown-mobile {
    margin: 0;
    margin-bottom: 16px;
    display: flex;
    position: absolute;
    top: 4rem;
  }

  .Librarian-new-chat-button.btn.btn-primary {
    width: 80%;
  }
}

.go-back-button {
  position:absolute;
  top: 1em;
}

.librarian-repository-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-bottom:4rem;

  h4 {
    padding-top: 1rem;
    padding-bottom: 0.3rem;
    font-size: 120%;
  }

  .librarian-repository-upload-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1em auto 0;
  }


  .librarian-repository-company-headline {
    align-self: center;
    text-align: center;
    padding: 2.5vh 0;
    font-size: 2em;
  }


  .librarian-repository-upload-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50vh;

    padding: 20px 10px;

    margin: auto;
    margin-top: 1%;
    height: 40vh;

    background-color: $SEMI_LIGHT_GREEN;

    border-radius: 25px;
    outline: none;
    overflow: auto;

    ul {
      padding-inline-start: 10px;
    }

    p:last-child {
      margin-top: 0;
      margin-bottom: 1.5em;
      text-align: center;
    }
  }

  .UploadDropzone-librarian-repository-files {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    margin: 0 20px;

    .UploadDropzone-remove-file {
      background-color: $SEMI_LIGHT_GREEN;
      border: 0;
      font-size: x-large;
      padding: 0 0 0 10px;
    }
  }

  .librarian-repository-upload-popup {
    min-width: 50vw;
    max-width: 60em;
    width: fit-content;
  }

  .librarian-repository-pHeading {
    font-size: 110%;
  }

  .librarian-repository-upload-rejected-files {
    width: fit-content;
    margin: auto;
    margin-top: 2rem;

    >h4 {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    >h4>span {
      color: red;
    }
  }

  .librarian-repository-upload-rejected-files-listgroup {
    max-height: 25vh;
    overflow: auto;
  }

  .librarian-repository-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .librarian-repository-processing-button-container {
    position: relative;
    width: 100%;
    margin-right: 20px;

    .librarian-repository-check-icon {
      position: absolute;
      margin: auto 5px auto 0;
      font-size: 1.5em;
    }
  }

  .librarian-repository-processing-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .librarian-repository-process-info-text {
    position: absolute;
    left: 50%;
    transform: translateX(100%);
    margin-left: 1.7em;
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.librarian-team-boxes-scroll {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2%;
  margin-bottom: 2%;
  overflow: auto;
}

.librarian-team-box {
  // Center text, but align left if there is more than one line
  // Different from `text-align: center;`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  margin: 12px;
  height: 6.3em;
  width: 10.5em;
  border-radius: 10px;
  padding: 0 0.5em;
}

.librarian-team-box-inner {
  font-size: 110%;
  color: black;
  p {
    text-align: center;
  }
}

.librarian-repository-box-inner {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;           /* Ensure the parent container height is used */
  text-align: center;
  overflow: hidden;       /* Ensure content does not exceed box */
}

.librarian-repository-box-inner p {
  display: block;           /* Ensure block-level behavior for paragraphs */
  white-space: normal;      /* Allows text to wrap */
  word-wrap: break-word;    /* Breaks long words for wrapping */
  overflow-wrap: anywhere;  /* Adds extra wrapping support */
  word-break: break-word;   /* Ensures compatibility across browsers */
  margin: 0;                /* Remove default margins */
  overflow-y: hidden;         /* Allow scrolling for overflowed content */
  text-align: center;       /* Keep text centered */
}



.librarian-team-fiSettings {
  position: absolute;
  right: 2%;
  bottom: 7%;
  width: 18%;
  height: 18%;
}

.librarian-team-consume {
  position: absolute;
  right: 17%;
  bottom: 7%;
  width: 18%;
  height: 18%;
}

.librarian-team-FiBarChart2 {
  position: absolute;
  left: 5%;
  bottom: 7%;
}

.librarian-team-fiCopy {
  position: absolute;
  left: 20%;
  bottom: 7%;
}

.librarian-team-addUser {
  position: absolute;
  left: 35%;
  bottom: 7%;
}

.forecast-team {
  font-size: 0.7em;
  position: absolute;
  top: 0;
  left: 5%;
  font-weight: 800;
  width: 100px;
  text-align: left;
}

.librarian-team-box-color {
  background-color: $LIGHT_BLUE;
}

.librarian-repository-box-color {
  background-color: $DARK_GREEN;
}

.librarian-chatbot-box-color {
  background-color: $ORANGE;
}

.admin-icons-hovereffect {
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
  }
}

.librarian-team-box-hovereffect {
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 0px 5px black;
  }
}

.librarian-team-fiPlus-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 100%;
  margin: auto;
  width: 100%;
  height: 40%;
  max-height: 90%;
}

.librarian-team-fiPlus {
  width: 100%;
  height: 120%;
  cursor: pointer;
}

.Librarian-history-conversations {
  margin: 1rem;
  border-radius: 10px;

  .conversation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;

    &:hover {
      background-color: #f0f4f8;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &.active {
      background-color: #86e0eb; // Light blue background for active conversation

      &:hover {
        background-color: #5bd4e3; // Slightly darker on hover for active
      }

      .conversation-info p {
        color: #007c91; // Change text color for active conversation
        font-weight: 600;
      }

      .conversation-icon svg {
        color: #007c91; // Change icon color for active conversation

        &:hover {
          color: #005662;
        }
      }
    }

    .conversation-info {
      p {
        font-size: 1rem;
        font-weight: 500;
        margin: 0;
        color: #333;
        transition: color 0.2s;
      }
    }

    .conversation-icon {
      display: flex;
      align-items: center;

      svg {
        font-size: 1.2rem;
        color: #666;
        transition: color 0.2s;

        &:hover {
          color: #333;
        }
      }
    }
  }
}
